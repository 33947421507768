import { $isParagraphNode, ElementFormatType, ParagraphNode, TextNode } from 'lexical';
import { CustomTextMatchTransformer } from '@/containers/PagesEditor/types';

const FORMAT_REGEXP = /<align style="text-align: (.+?);">(.*)/;
const REGEXP = /(<align.*)|(.*<\/align>)/;

const replaceOneNode = (node: TextNode) => {
  const parent = node.getParent();
  if (!$isParagraphNode(parent)) return [node];

  const formatMatch = node.getTextContent().match(FORMAT_REGEXP);
  if (formatMatch) {
    const [, format] = formatMatch;
    parent.setFormat(format as ElementFormatType);
    node.setTextContent(node.getTextContent().replace(`<align style="text-align: ${format};">`, ''));
  }

  node.setTextContent(node.getTextContent().replace('</align>', ''));
  if (node.getTextContent().length) return [node];

  node.remove();
  return [];
};

export const ALIGNMENT_TRANSFORMER: CustomTextMatchTransformer = {
  dependencies: [TextNode, ParagraphNode],
  export: (node, textContent) => {
    const parent = node.getParent();
    if (!$isParagraphNode(parent)) return textContent;

    const format = parent.getFormatType();
    if (!format || format === 'left') return textContent;

    const result = [textContent];
    if (parent.getFirstChild() === node) result.unshift(`<align style="text-align: ${format};">`);
    if (parent.getLastChild() === node) result.push('</align>');

    return result.join('');
  },
  importRegExp: REGEXP,
  regExp: REGEXP,
  replace: nodes => nodes.flatMap(replaceOneNode),
  type: 'text-match',
};
