import { Components, Theme } from '@mui/material/styles';

const MuiBreadcrumbs: Components<Theme>['MuiBreadcrumbs'] = {
  styleOverrides: {
    root: {
      overflow: 'auto',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': { display: 'none' },
    },
    ol: {
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
    },
    li: ({ theme }) => ({
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
      color: theme.palette.text.primary,
    }),
  },
};
export default MuiBreadcrumbs;
