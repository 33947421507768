import { Components, Theme } from '@mui/material/styles';

const MuiLinearProgress: Components<Theme>['MuiLinearProgress'] = {
  styleOverrides: {
    barColorPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.primary.dark,
    }),
  },
};
export default MuiLinearProgress;
