import { createContext } from 'react';
import { ISidebarMode } from 'pspdfkit';
import { CompareSourcesConfig, CountingMode } from '@/containers/DocumentEditor/types';
import { ConstructingEquipmentPiece, DocumentMetadata } from '@/api/generated';
import { type useMultiCountTool } from '@/containers/DocumentEditor/internalHooks/useMultiCountTool';
import { type useAutoCountTool } from '@/containers/DocumentEditor/internalHooks/useAutoCountTool';
import { type useToolbarCategory } from '@/containers/DocumentEditor/internalHooks/useToolbarCategory';

type EditorContextValue = {
  setDocumentEditorContainerRef: (element: HTMLDivElement) => void;
  setCompareContainerRef: (element: HTMLDivElement) => void;
  setSecondCompareContainerRef: (element: HTMLDivElement) => void;
  setSingleContainerRef: (element: HTMLDivElement) => void;
  isDocumentEditorEnabled: boolean;
  documentsToCompare: CompareSourcesConfig | null;
  comparedDocuments?: { firstDocument: DocumentMetadata; secondDocument: DocumentMetadata };
  countingMode: CountingMode;
  sidebarMode: ISidebarMode | 'FAVORITES' | null;
  page: number;
  totalPagesCount: number;
  selectedEquipmentItems: ConstructingEquipmentPiece[] | null;
  multiCountColor: ReturnType<typeof useMultiCountTool>['multiCountColor'];
  toolbarCategory: ReturnType<typeof useToolbarCategory>['toolbarCategory'];
  autoCountingTempAnnotation: ReturnType<typeof useAutoCountTool>['autoCountingTempAnnotation'];
};

export const EditorContext = createContext<EditorContextValue | null>(null);
