import { Instance, ViewState } from 'pspdfkit';

type Params = {
  instance: Instance;
  viewState: ViewState;
};

export const togglePanAndScrollOnScrollModeChange = ({ instance, viewState }: Params) => {
  if (viewState.scrollMode === 'PER_SPREAD' && !viewState.enableAlwaysScrollToZoom) {
    instance.setViewState(currentViewState =>
      currentViewState.set('enableAlwaysScrollToZoom', true).set('interactionMode', 'PAN'),
    );
  } else if (viewState.scrollMode === 'CONTINUOUS' && viewState.enableAlwaysScrollToZoom) {
    instance.setViewState(currentViewState =>
      currentViewState
        .set('enableAlwaysScrollToZoom', false)
        .set('interactionMode', currentViewState.interactionMode === 'PAN' ? null : currentViewState.interactionMode),
    );
  }
};
