import { FC } from 'react';
import { Grid, Skeleton, useTheme } from '@mui/material';

const ProjectsSkeleton: FC = () => {
  const { palette } = useTheme();

  return (
    <Skeleton variant="rectangular" width="100%" sx={{ borderBottomRadius: 1 }} height={94}>
      <Grid
        container
        xs={12}
        sx={{ display: 'flex', alignItems: ' center', height: '100%', borderBottom: `1px solid ${palette.grey[200]}` }}
      >
        {Array(6)
          .fill(null)
          .map((_, index) => (
            <Grid key={index} item xs={2} sx={{ p: 2, textTransform: 'uppercase', fontSize: 'body2.fontSize' }}>
              <Skeleton variant="text" height={30} sx={{ backgroundColor: palette.grey[200], visibility: 'visible' }} />
            </Grid>
          ))}
      </Grid>
    </Skeleton>
  );
};

export default ProjectsSkeleton;
