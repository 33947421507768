import { FC } from 'react';
import { Dialog as MuiDialog, DialogProps as MuiDialogProps, useTheme } from '@mui/material';

interface DialogProps extends MuiDialogProps {
  width?: number | string;
}

const Dialog: FC<DialogProps> = ({ sx, PaperProps, width = 500, ...props }) => {
  const { palette, shadows, shape } = useTheme();

  return (
    <MuiDialog
      {...props}
      maxWidth="md"
      sx={{ ...sx, '& .MuiBackdrop-root': { backgroundColor: 'rgba(0,0,0,0.1)' } }}
      PaperProps={{
        ...PaperProps,
        sx: {
          width,
          p: 4,
          pb: 4,
          backgroundColor: palette.background.default,
          borderRadius: shape.borderRadius,
          boxShadow: shadows[4],
          border: `1px solid ${palette.grey[300]}`,
          ...(PaperProps?.sx ?? {}),
        },
      }}
    />
  );
};

export default Dialog;
