import { Stack, StackProps, useTheme } from '@mui/material';
import { FC } from 'react';
import { DropzoneState } from 'react-dropzone';

interface FilesDropZoneProps extends StackProps {
  isDragActive: DropzoneState['isDragActive'];
}

const DropZoneArea: FC<FilesDropZoneProps> = ({ isDragActive, sx, ...props }) => {
  const { palette } = useTheme();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        zIndex: 1000,
        borderRadius: 4,
        border: `1px dashed ${isDragActive ? palette.darkPurple.dark : palette.darkPurple.light}`,
        boxShadow: 6,
        backgroundColor: palette.background.default,
        cursor: 'pointer',
        ...sx,
      }}
      {...props}
    />
  );
};

export default DropZoneArea;
