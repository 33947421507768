import { useMemo } from 'react';
import { ConstructingEquipmentPiece, EquipmentPiece, UnitType } from '@/api/generated';

type Params = {
  equipmentList: EquipmentPiece[];
};

export const useAnnotationsByType = ({ equipmentList }: Params) =>
  useMemo(
    () =>
      equipmentList.reduce<Record<UnitType, ConstructingEquipmentPiece[]>>(
        (acc, equipment) => {
          const key = equipment.unitType!;
          return { ...acc, [key]: [...(acc[key] || []), ...(equipment.constructing_equipment_items ?? [])] };
        },
        { [UnitType.linear]: [], [UnitType.area]: [], [UnitType.pieces]: [] },
      ),
    [equipmentList],
  );
