import { Mention, MentionType } from '@/api/generated';

export const ejectMentionsFromText = (text: string) => {
  const mentions: Mention[] = [];
  const simplifiedText = text
    .replace(/@\[(.+?)]\((.+?)\)/g, (_, ...args) => {
      const [fileName, id] = args;
      mentions.push({ id, name: fileName, mention_type: MentionType.document });
      return `@${fileName}`;
    })
    .replace(/#\[(.+?)]\((.+?)\)/g, (_, ...args) => {
      const [fileName, id] = args;
      mentions.push({ id, name: fileName.split(' ').join('_'), mention_type: MentionType.tool });
      return `#${fileName}`;
    });

  return { text: simplifiedText, mentions };
};
