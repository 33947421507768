import { SuggestionDataItem as MentionSuggestionDataItem } from 'react-mentions';

export interface SuggestionDataItem extends MentionSuggestionDataItem {
  description?: string;
  type: string;
}

export const getMentionTypeOptions = (documents: SuggestionDataItem[], tools: SuggestionDataItem[]) => [
  {
    data: documents,
    trigger: /((?:@|documents:|document:)(.*))/,
    displayTransform: (id: string, display: string) => `@${display}`,
    markup: '@[__display__](__id__)',
  },
  {
    data: tools,
    trigger: /((?:#|tool:|tools:)(.*))/,
    displayTransform: (id: string, display: string) => `#${display}`,
    markup: '#[__display__](__id__)',
  },
];
