import MuiButton from './MuiButton';
import MuiAppBar from './MuiAppBar';
import MuiIconButton from '@/theme/components/MuiIconButton';
import MuiSelect from '@/theme/components/MuiSelect';
import MuiTabs from '@/theme/components/MuiTabs';
import MuiTab from '@/theme/components/MuiTab';
import MuiToolbar from '@/theme/components/MuiToolbar';
import MuiChip from '@/theme/components/MuiChip';
import MuiTooltip from '@/theme/components/MuiTooltip';
import MuiOutlinedInput from '@/theme/components/MuiOutlinedInput';
import MuiFormLabel from '@/theme/components/MuiFormLabel';
import MuiAutocomplete from '@/theme/components/MuiAutocomplete';
import MuiPopper from '@/theme/components/MuiPopper';
import MuiBreadcrumbs from '@/theme/components/MuiBreadcrumbs';
import MuiPaper from '@/theme/components/MuiPaper';
import MuiSvgIcon from '@/theme/components/MuiSvgIcon';
import MuiFab from '@/theme/components/MuiFab';
import MuiCircularProgress from '@/theme/components/MuiCircularProgress';
import MuiLinearProgress from '@/theme/components/MuiLinearProgress';
import MuiRadio from '@/theme/components/MuiRadio';
import MuiCheckbox from '@/theme/components/MuiCheckbox';
import MuiSlider from '@/theme/components/MuiSlider';
import MuiSwitch from '@/theme/components/MuiSwitch';
import MuiPaginationItem from '@/theme/components/MuiPaginationItem';
export default {
  MuiButton,
  MuiIconButton,
  MuiAppBar,
  MuiSelect,
  MuiTabs,
  MuiTab,
  MuiToolbar,
  MuiChip,
  MuiOutlinedInput,
  MuiFormLabel,
  MuiAutocomplete,
  MuiBreadcrumbs,
  MuiPaper,
  MuiPopper,
  MuiSvgIcon,
  MuiTooltip,
  MuiFab,
  MuiRadio,
  MuiCheckbox,
  MuiSlider,
  MuiCircularProgress,
  MuiLinearProgress,
  MuiSwitch,
  MuiPaginationItem,
};
