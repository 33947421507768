import { FC, KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { NodeApi, NodeRendererProps } from 'react-arborist';
import { Box, CircularProgress, IconButton, Input, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Icon from '@/components/Icon/Icon';
import { STATUS } from '@/utils/enums';
import { Key } from 'ts-key-enum';
import { BsThreeDots } from 'react-icons/bs';
import Actions from '@/views/Projects/components/Actions/Actions';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { MenuItemProps } from '@/components/MoreActions/MoreActions';
import { TreeFileSystemNode } from '@/types';
import LinearProgressWithLabel from '@/components/LinearProgressWithLabel';

interface TreeDocumentNodeProps extends NodeRendererProps<TreeFileSystemNode> {
  onAddFile: (node: NodeApi<TreeFileSystemNode>) => void;
  onFileOpen: (node: NodeApi<TreeFileSystemNode>) => void;
  onDocumentConvertToPage: (documentId: string) => void;
}

const TreeDocumentNode: FC<TreeDocumentNodeProps> = ({
  node,
  style,
  dragHandle,
  tree,
  onAddFile,
  onFileOpen,
  onDocumentConvertToPage,
}) => {
  const { t } = useTranslation('projectUpdate');
  const { palette } = useTheme();
  const actionsState = usePopupState({
    variant: 'popover',
    popupId: `tree-file-system-actions-${node.data.id}`,
  });

  const { type, name, status } = node.data;
  const isReadOnly = status !== STATUS.LOADED;
  const [documentName, setDocumentName] = useState(name);
  const [isDragOver, setIsDragOver] = useState(false);
  const isLoading = node.data.status === STATUS.LOADING;
  const isFailed = node.data.status === STATUS.ERROR;

  const elementBgColor =
    (node.data.type === 'folder' && isDragOver) || node.willReceiveDrop || node.isFocused ? palette.grey[100] : 'inherit';

  const save = () => documentName.trim() && node.submit(documentName.trim());

  const onAddFolder = (event: MouseEvent) => {
    if (isReadOnly) return;

    event.stopPropagation();
    tree.create({ parentId: node.id });
  };

  const onDelete = (event: MouseEvent) => {
    if (isReadOnly) return;

    event.stopPropagation();
    tree.delete(node);
  };

  const onEdit = (event: MouseEvent) => {
    if (isReadOnly) return;

    event.stopPropagation();
    tree.edit(node);
  };

  const onRootClick = (event: MouseEvent) => {
    if (node.isEditing || isReadOnly) return;

    const isClickedOnButton = (event.target as HTMLElement).closest('button');

    if (node.data.type === 'folder' && !isClickedOnButton) {
      node.toggle();
    } else {
      onFileOpen(node);
    }
  };

  const handleAddFile = (event: MouseEvent) => {
    if (isReadOnly) return;

    event.stopPropagation();
    onAddFile(node);
  };

  const onDragEnter = () => setIsDragOver(true);
  const onDragLeave = () => setIsDragOver(false);

  const onKeyDown = (event: KeyboardEvent) => event.code == Key.Enter && save();
  const onBlur = () => save();

  const menuItems: MenuItemProps[] = [
    type === 'folder' && {
      id: 'uploadFiles.addFolder',
      children: t('uploadFiles.addSubFolder'),
      onClick: onAddFolder,
    },
    {
      id: 'uploadFiles.delete',
      children: type === 'folder' ? t('uploadFiles.deleteFolder') : t('uploadFiles.deleteFile'),
      onClick: onDelete,
    },
    { id: 'actions.edit', children: t('uploadFiles.rename'), onClick: onEdit },
    type !== 'folder' && {
      id: 'actions.convert',
      children: t('uploadFiles.convert.menuItem'),
      onClick: () => onDocumentConvertToPage(node.id),
    },
  ].filter(item => !!item);

  useEffect(() => {
    setDocumentName(node.data.name);
  }, [node.data]);

  const iconName = type === 'file' ? 'pdf' : node.isOpen ? 'folderOpen' : 'folder';

  return (
    <Stack
      ref={dragHandle}
      style={style}
      data-folderid={node.data.type === 'folder' ? node.data.id : undefined}
      title={isFailed ? (node.data.type === 'folder' ? t('uploadFiles.toggleFolder') : t('uploadFiles.openFile')) : undefined}
      sx={{
        height: '100%',
        mx: 3,
        fontWeight: 400,
        borderRadius: 1,
        borderTop: node.isDragging ? '1px dashed' : 'none',
        borderBottom: node.isDragging ? '1px dashed' : 'none',
        backgroundColor: elementBgColor,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: node.isSelected || node.isFocused ? '' : palette.grey[100],
          '& .TreeDocumentNode__actions': { opacity: 1 },
        },
      }}
      onClick={onRootClick}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1} sx={{ height: '100%', px: 1 }}>
        <Stack direction="row" alignItems="center" gap={1} sx={{ minWidth: 0 }}>
          {node.data.status === STATUS.DELETING ? (
            <Box sx={{ width: 16, height: 16 }}>
              <CircularProgress size={16} />
            </Box>
          ) : (
            <Icon name={iconName} fontSize="small" htmlColor={palette.primary.dark} sx={{ flexShrink: 0 }} />
          )}

          {node.isEditing ? (
            <Input
              autoFocus
              value={documentName}
              onChange={event => setDocumentName(event.target.value)}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
            />
          ) : (
            <Box
              sx={{
                width: '100%',
                fontSize: 'body2.fontSize',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: isLoading || isFailed ? palette.grey[400] : palette.text.primary,
              }}
            >
              {documentName}
            </Box>
          )}
        </Stack>

        {!isReadOnly && (
          <Stack
            direction="row"
            alignItems="center"
            className="TreeDocumentNode__actions"
            sx={{ opacity: actionsState.isOpen ? 1 : 0 }}
          >
            <Actions
              stopPropagation
              icon={<BsThreeDots color={palette.primary.dark} size={16} />}
              sx={{ '&:hover': { backgroundColor: palette.primary.main }, zIndex: 1 }}
              menuItems={menuItems}
              actionsState={actionsState}
            />

            {type === 'folder' && (
              <IconButton
                size="small"
                title={t('uploadFiles.addFile')}
                sx={{ '&:hover': { backgroundColor: palette.primary.main } }}
                onClick={handleAddFile}
              >
                <Icon name="plus" fontSize="small" htmlColor={palette.primary.dark} />
              </IconButton>
            )}
          </Stack>
        )}
      </Stack>
      {isLoading && (
        <LinearProgressWithLabel
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: -6,
            mx: 4,
          }}
          height={2}
          progress={node.data.progress}
        />
      )}
    </Stack>
  );
};

export default TreeDocumentNode;
