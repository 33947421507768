import { FC, ReactNode } from 'react';
import { Stack, SxProps } from '@mui/material';
import DocumentDelete from '@/components/DocumentDelete/DocumentDelete';

interface DocumentItemWrapperProps {
  children: ReactNode;
  sx?: SxProps;
  onDelete: () => void;
  size?: string;
}

const DocumentItemWrapper: FC<DocumentItemWrapperProps> = ({ children, onDelete, sx, size = 'body3.fontSize' }) => (
  <Stack flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2, ...sx }}>
    {children}
    <DocumentDelete sx={{ fontSize: size }} onDelete={onDelete} />
  </Stack>
);

export default DocumentItemWrapper;
