import { File, Folder } from '@/api/generated';
import { TreeFileSystemNode } from '@/types';

export const convertFilSystemTreeToServerFileSystem = (treeNodesById: Record<string, TreeFileSystemNode>): Array<File | Folder> =>
  Object.entries(treeNodesById).map(([, node]) =>
    node.type === 'folder'
      ? {
          id: node.id,
          isFolder: true,
          name: node.name,
          order: node.order,
          parentId: node.parentId ?? 'root',
        }
      : {
          file_type: 'pdf',
          id: node.id,
          isFolder: false,
          order: node.order,
          parentId: node.parentId ?? 'root',
        },
  );
