import { InputLabel, Stack, SxProps, TextField, Typography, useTheme, TextFieldProps, StackProps } from '@mui/material';
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

interface TextInputProps<T extends FieldValues = FieldValues> extends Omit<TextFieldProps, 'name' | 'control' | 'translate'> {
  label?: string;
  control: Control<T>;
  name: FieldPath<T>;
  id: string;
  translate?: string;
  labelSx?: SxProps;
  isRequired?: boolean;
  hideErrors?: boolean;
  width?: StackProps['width'];
  sx?: SxProps;
  onChange?: () => void;
}

const TextInput: <T extends FieldValues>(props: TextInputProps<T>) => ReactNode = ({
  label,
  placeholder,
  control,
  name,
  id,
  translate,
  labelSx,
  isRequired,
  hideErrors,
  width = '100%',
  sx,
  onChange,
  ...textFieldProps
}) => {
  const { palette, typography } = useTheme();
  // TODO move translations outside the input.
  const { t } = useTranslation(translate || 'common', { useSuspense: !!translate });
  const { field, fieldState } = useController({ name, control });
  const { ref, ...fieldProps } = field;

  return (
    <Stack gap={0.5} width={width} sx={sx}>
      {label && (
        <InputLabel sx={{ color: palette.controls.text, ...labelSx }} htmlFor={id}>
          <Stack direction="row">
            {label}
            {<Stack color={palette.error.main}>{isRequired ? '*' : ''}</Stack>}
          </Stack>
        </InputLabel>
      )}

      <TextField
        id={id}
        {...fieldProps}
        {...textFieldProps}
        inputRef={ref}
        onChange={event => {
          field.onChange(event);
          onChange?.();
        }}
        error={!!fieldState.error}
        placeholder={placeholder}
        autoComplete="off"
      />

      {!hideErrors && !!fieldState.error?.message && (
        <Typography sx={{ color: palette.error.main, fontSize: typography.body3.fontSize }}>
          {t(fieldState.error.message)}
        </Typography>
      )}
    </Stack>
  );
};

export default TextInput;
