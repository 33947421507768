import { useEffect } from 'react';

export const useDispatchRootClickEvent = (instanceRoot?: HTMLDivElement | null) => {
  useEffect(() => {
    const handleRootClick = () => {
      document.dispatchEvent(new CustomEvent('pspdfRootClick'));
    };

    instanceRoot?.addEventListener('click', handleRootClick);

    return () => {
      instanceRoot?.removeEventListener('click', handleRootClick);
    };
  }, [instanceRoot]);
};
