import { Components, Theme } from '@mui/material/styles';

const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      border: 'none',
      backgroundColor: theme.palette.controls.background,
      '&:hover:not(.Mui-error), &.Mui-focused, &.Mui-disabled': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
      },
    }),
    input: ({ theme }) => ({
      padding: theme.spacing(1.5),
      fontSize: theme.typography.body1.fontSize,
      '&::placeholder': {
        color: theme.palette.grey[400],
        opacity: 1,
      },
      '&.MuiInputBase-inputSizeSmall': {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      },
    }),
    notchedOutline: {
      borderColor: 'transparent',
    },
    multiline: {
      padding: 0,
    },
  },
};
export default MuiOutlinedInput;
