import { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, SxProps, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface ListDeleteButtonProps {
  className?: string;
  sx?: SxProps<Theme>;
  onClick: () => void;
}

export const ListDeleteButton: FC<ListDeleteButtonProps> = ({ className, sx, onClick }) => {
  const { t } = useTranslation('project');
  const { typography } = useTheme();

  return (
    <IconButton
      color="primary"
      className={className}
      title={t('actions.delete')}
      sx={{ '&, &:hover': { p: 0.25, boxShadow: 'none' }, ...sx }}
      onClick={onClick}
    >
      <CloseIcon sx={{ fontSize: typography.body1 }} />
    </IconButton>
  );
};
