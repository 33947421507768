import { BaseUser } from '@/api/generated';
import { FC, useMemo } from 'react';
import { SxProps, Avatar, Typography } from '@mui/material';

interface UserAvatarProps {
  user: BaseUser;
  sx?: SxProps;
  initialsSx?: SxProps;
  size?: number;
}

const UserAvatar: FC<UserAvatarProps> = ({ user, size, sx = {}, initialsSx = {} }) => {
  const initials = useMemo(() => `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`, [user]);

  return (
    <Avatar
      sx={{
        ...(size && { height: size, width: size }),
        backgroundColor: stringToColor(initials),
        ...sx,
      }}
      src={user.avatar}
      alt={`${user.firstName} ${user.lastName}`}
    >
      <Typography fontSize="body1.fontSize" textTransform="uppercase" sx={initialsSx}>
        {initials}
      </Typography>
    </Avatar>
  );
};

const stringToColor = (initials: string) => {
  const hash = [...initials].reduce((accumulator, char) => char.charCodeAt(0) + ((accumulator << 5) - accumulator), 0);

  return `#${Array.from({ length: 3 })
    .map((_, i) => {
      const value = (hash >> (i * 8)) & 0xff;
      return `00${value.toString(16)}`.slice(-2);
    })
    .join('')}`;
};

export default UserAvatar;
