import { FC, MouseEvent, ReactNode } from 'react';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import Dialog from '@/components/Dialog/Dialog';

export type ConfirmDialogTexts = {
  title: ReactNode;
  description?: ReactNode;
  subDescription?: ReactNode;
  confirm?: string;
  cancel?: string;
};

interface ConfirmDialogProps {
  isOpened?: boolean;
  texts: ConfirmDialogTexts;
  onClose: (result?: boolean) => void;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({ isOpened, texts, onClose }) => {
  const handleClose = (event: MouseEvent, result?: boolean) => {
    event.stopPropagation();
    onClose(result);
  };
  const { palette, typography } = useTheme();

  return (
    <Dialog
      PaperProps={{ sx: { '&.MuiPaper-root': { py: 6, borderRadius: 2 } } }}
      id="confirm-dialog"
      width={572}
      open={!!isOpened}
      onClose={event => handleClose(event as MouseEvent)}
    >
      <Stack gap={3.5}>
        <Typography fontWeight="fontWeightMedium" textAlign="center" sx={{ color: palette.grey[800] }}>
          {texts.title}
        </Typography>
        <Stack>
          <Typography variant="body2" textAlign="center" sx={{ color: palette.grey[800] }}>
            {texts.description}
          </Typography>
          {texts.subDescription && (
            <Typography variant="body2" textAlign="center" sx={{ color: palette.grey[800] }}>
              {texts.subDescription}
            </Typography>
          )}
        </Stack>
        <Stack direction="row" gap={8.5} mt={2} justifyContent="center">
          <Button
            sx={{ width: 100, py: 1, px: 7, color: palette.secondary.light, fontWeight: typography.fontWeightRegular }}
            color="secondary"
            variant="contained"
            onClick={event => handleClose(event, false)}
          >
            {texts.cancel}
          </Button>
          <Button sx={{ width: 100, py: 1, px: 7 }} variant="contained" onClick={event => handleClose(event, true)}>
            {texts.confirm}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ConfirmDialog;
