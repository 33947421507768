import { useEffect, useState } from 'react';
import { headerId } from '@/components/Header/Header';

const useHeaderHeight = () => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const header = document.getElementById(headerId);
    setHeight(header?.clientHeight || 0);

    const handleResize = () => setHeight(header?.clientHeight || 0);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return height;
};

export default useHeaderHeight;
