import { Components, Theme } from '@mui/material';

const MuiSvgIcon: Components<Theme>['MuiSvgIcon'] = {
  variants: [
    {
      props: { fontSize: 'xsmall' },
      style: { fontSize: '0.75rem' },
    },
    {
      props: { fontSize: 'small' },
      style: { fontSize: '1rem' },
    },
    {
      props: { fontSize: 'medium' },
      style: { fontSize: '1.25rem' },
    },
    {
      props: { fontSize: 'large' },
      style: { fontSize: '1.5rem' },
    },
  ],
};

export default MuiSvgIcon;
