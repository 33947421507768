import { useLoader } from '@/hooks/useLoader';
import { useEffect } from 'react';

interface LoaderProps {
  id: string;
}

const Loader = ({ id }: LoaderProps) => {
  const { addLoadingId, removeLoadingId } = useLoader();

  useEffect(() => {
    const loadingId = `${crypto.randomUUID()}-${id}`;

    addLoadingId(loadingId);

    return () => removeLoadingId(loadingId);
  }, [addLoadingId, id, removeLoadingId]);

  return <></>;
};

export default Loader;
