import { Grid, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { formatDistanceToNow } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { usePopupState } from 'material-ui-popup-state/hooks';
import clsx from 'clsx';
import { ProjectFull, Status, useChangeProjectStatusHook, User } from '@/api/generated';
import { toProjectHomepage } from '@/services/linker';
import Actions from '../Actions/Actions';
import Cell from '../Cell/Cell';
import { MenuItemProps } from '@/components/MoreActions/MoreActions';
import ShareProjectDialog from '@/views/ShareProjectDialog/ShareProjectDialog';
import { addUserToProjectCollaborationList, removeUserFromProjectCollaborationList } from '@/utils/projectCollaborationList';
import { useProjectFormDialog } from '@/hooks/useProjectFormDialog';
import { updateProjectCache } from '@/utils/updateProjectCache';

interface ProjectProps {
  project: ProjectFull;
  onEditFinish: () => void;
  onRefetch: () => void;
}

const Project: FC<ProjectProps> = ({ project, onEditFinish, onRefetch }) => {
  const { palette } = useTheme();
  const { t } = useTranslation('projects');
  const queryClient = useQueryClient();
  const { showProjectFormDialog } = useProjectFormDialog();
  const actionState = usePopupState({
    variant: 'popover',
    popupId: `projects-project-actions-${project.slug}`,
  });
  const onChangeStatus = useChangeProjectStatusHook();

  const [isShareModalOpened, setIsShareModalOpened] = useState(false);
  const lastModified = project.last_modified ? formatDistanceToNow(new Date(project.last_modified), { addSuffix: true }) : '';

  const { name, type, owner, location, delivery_method } = project;

  const openProjectFormDialog = async () => {
    const isSaved = await showProjectFormDialog(project.slug);
    isSaved && onEditFinish();
  };

  const handleStatusChange = async () => {
    const currentStatus = project.status === Status.active ? Status.done : Status.active;
    await onChangeStatus(project.slug, currentStatus);
    await onRefetch();
  };

  const menuItems: MenuItemProps[] = [
    { id: 'actions.edit', children: t('list.actions.edit'), onClick: openProjectFormDialog },
    { id: 'actions.share', children: t('list.actions.share'), onClick: () => setIsShareModalOpened(true) },
    {
      id: 'status',
      children: t(`list.actions.${project.status === Status.active ? 'archive' : 'active'}`),
      onClick: handleStatusChange,
    },
  ];

  const onShare = (sharedUser: User) => {
    updateProjectCache({ queryClient, projectSlug: project.slug }, () =>
      addUserToProjectCollaborationList({ user: sharedUser, project }),
    );
  };

  const onUnshare = (unsharedUser: User) => {
    updateProjectCache({ queryClient, projectSlug: project.slug }, () =>
      removeUserFromProjectCollaborationList({ user: unsharedUser, project }),
    );
  };

  return (
    <>
      <Grid
        item
        className={clsx({ isOpen: actionState.isOpen })}
        component={Link}
        to={toProjectHomepage({ projectSlug: project.slug })}
        xs={12}
        sx={{
          borderBottom: `1px solid ${palette.grey[200]}`,
          color: palette.text.secondary,
          fontWeight: 400,
          cursor: 'pointer',
          textDecoration: 'none',

          '&:nth-of-type(even)': {
            backgroundColor: palette.grey[100],
          },
          '&:last-child , &:last-child ': {
            border: 0,
          },
          '&.isOpen': {
            backgroundColor: palette.action.hover,
          },
          '&:hover': {
            backgroundColor: palette.action.hover,
            '.actions': {
              opacity: 1,
              pointerEvents: 'auto',
            },
          },
          py: 0,
          pt: 3,
          pb: 3,
        }}
      >
        <Grid container item xs={12} alignItems="center" columnSpacing={1}>
          <Cell xs={2} allowEllipsis fontWeight="fontWeightMedium" fontSize="h3.fontSize">
            {name}
          </Cell>
          <Cell xs={2} allowEllipsis sx={{ textTransform: 'capitalize' }}>
            {type}
          </Cell>

          <Cell xs={2} allowEllipsis>
            {delivery_method}
          </Cell>
          <Cell xs={2} fontWeight="fontWeightRegular">
            <Trans shouldUnescape>{t('list.lastModified', { date: lastModified })}</Trans>
          </Cell>
          <Cell xs={2} allowEllipsis>
            {owner}
          </Cell>

          <Grid item xs={1.5}>
            <Cell>
              {location && (
                <Trans shouldUnescape>
                  {t('list.address', {
                    number: location.number,
                    street: location.street,
                    city: location.city,
                    zip: location.zip,
                    state: location.state,
                  })}
                </Trans>
              )}
            </Cell>
          </Grid>
          <Grid
            item
            xs={0.5}
            className="actions"
            sx={{
              opacity: actionState.isOpen ? '1' : '0',
              pointerEvents: 'none',
            }}
          >
            <Actions actionsState={actionState} stopPropagation menuItems={menuItems} />
          </Grid>
        </Grid>
      </Grid>
      <ShareProjectDialog
        isOpen={isShareModalOpened}
        project={project}
        toggleModal={() => setIsShareModalOpened(false)}
        onShare={onShare}
        onUnshare={onUnshare}
      />
    </>
  );
};

export default Project;
