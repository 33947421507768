import { ElementType, Suspense } from 'react';
import Loader from '../Loader/Loader';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Loadable = (Component: ElementType) => (props: any) => (
  <Suspense fallback={<Loader id="loadable-routers" />}>
    <Component {...props} />
  </Suspense>
);

export default Loadable;
