import { FC } from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { useTheme } from '@mui/material';

interface SubmitButtonProps extends LoadingButtonProps {
  isLoading?: boolean;
  isDisabled?: boolean;
}

const SubmitButton: FC<SubmitButtonProps> = ({ children, isLoading = false, isDisabled = false, sx, ...props }) => {
  const { palette } = useTheme();

  return (
    <LoadingButton
      {...props}
      disabled={isDisabled}
      sx={{
        ...sx,
        fontSize: 'body2.fontSize',
        '& ,&:hover': {
          backgroundColor: palette.primary.main,
          color: palette.primary.dark,
        },
        '&:hover': {
          boxShadow: 5,
        },
      }}
      type="submit"
      loading={isLoading}
      variant={isDisabled ? 'contained' : undefined}
    >
      {children}
    </LoadingButton>
  );
};

export default SubmitButton;
