import PSPDFKit, { AnnotationBackendJSON, Instance } from 'pspdfkit';

// This method almost the same as PSPDF's exportInstantJSON.
// But we don't use exportInstantJSON as communication with server,
// exportInstantJSON changes ids which should to be skipped on a server.
export const exportAnnotationsJson = async (instance: Instance) => {
  const annotationsPerPage = await Promise.all(Array.from(Array(instance.totalPageCount).keys()).map(instance.getAnnotations));

  const annotations = annotationsPerPage
    .reduce((allAnnotations, pageAnnotations) => pageAnnotations.concat(allAnnotations))
    .map(annotation => PSPDFKit.Annotations.toSerializableObject(annotation) as unknown as AnnotationBackendJSON);

  return annotations.toJSON();
};
