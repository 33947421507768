import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TitleContext } from '@/contexts/TitleContext';

interface TitleProviderProps {
  children: React.ReactNode;
}

const TitleProvider: FC<TitleProviderProps> = ({ children }) => {
  const { t } = useTranslation('common');
  const [pageTitle, setPageTitle] = useState(t('title.suffix'));

  const updatePageTitle = (title: string) => setPageTitle(t('title.template', { title }));

  return <TitleContext.Provider value={{ pageTitle, setPageTitle: updatePageTitle }}>{children}</TitleContext.Provider>;
};

export default TitleProvider;
