import { useCallback, useMemo, useRef } from 'react';
import { AnnotationBackendJSON } from 'pspdfkit';
import debounce from 'lodash/debounce';
import uniq from 'lodash/uniq';
import { filterUselessAnnotations } from '@/utils/filterUselessAnnotations';
import { removeClientDataFromAnnotations } from '@/utils/removeClientDataFromAnnotations';
import { AnnotationEventType, usePostProjectsIdDocumentsDocumentIdAnnotationsHook } from '@/api/generated';
import { useAnnotations } from '@/views/Project/hooks/useAnnotations';
import { useProject } from '@/views/Project/hooks/useProject';
import { useDocuments } from '@/views/Project/hooks/useDocuments';

type OperationsStack = Record<AnnotationEventType, AnnotationBackendJSON[]>;

const applyEventChanges = (
  eventType: AnnotationEventType,
  allAnnotations: AnnotationBackendJSON[],
  eventAnnotations: AnnotationBackendJSON[],
  eventAnnotationIds: string[],
) => {
  if (eventType === 'create') return [...allAnnotations, ...eventAnnotations];
  if (eventType === 'delete') return allAnnotations.filter(annotation => !eventAnnotationIds.includes(annotation.id));
  if (eventType === 'update')
    return allAnnotations.map(
      annotation => eventAnnotations.find(eventAnnotation => eventAnnotation.id === annotation.id) ?? annotation,
    );
  return allAnnotations;
};

const getInitialState = () =>
  (['create', 'delete', 'update'] as const).reduce<OperationsStack>(
    (state, eventType) => {
      state[eventType] = [];
      return state;
    },
    {
      create: [],
      delete: [],
      update: [],
    },
  );

// Updates annotations only on server side, so should be used in case we have pspdf on a page.
export const useUpdateAnnotations = () => {
  const { projectSlug } = useProject();
  const { documentId } = useDocuments();
  // Document ref is used here to keep data actual (since we send it on every update to BE) while we are working with pspdf update events.
  const { documentAnnotationsRef, refetchDocumentData, onUpdateDocumentData } = useAnnotations();
  const operationsStackRef = useRef(getInitialState());
  const updateDocumentAnnotations = usePostProjectsIdDocumentsDocumentIdAnnotationsHook();

  const updateAnnotations = useMemo(
    () =>
      debounce(async (allAnnotations: AnnotationBackendJSON[]) => {
        Object.entries(operationsStackRef.current).map(async ([eventType, eventAnnotations]) => {
          const eventAnnotationIds = uniq(eventAnnotations.map(annotation => annotation.id));
          if (!eventAnnotationIds.length) return;

          const updatedDocument = await updateDocumentAnnotations(projectSlug, documentId!, {
            eventType: eventType as AnnotationEventType,
            eventAnnotationIds,
            annotations: removeClientDataFromAnnotations(allAnnotations),
          });

          onUpdateDocumentData({ equipment_list: updatedDocument.equipment_list ?? [] });

          // We start fetching for auto counting because backend generates data for it.
          if (
            eventType === 'create' &&
            eventAnnotations.find(annotation => annotation.customData?.specialType === 'autoAnnotationParent')
          ) {
            refetchDocumentData();
          }
        });
        operationsStackRef.current = getInitialState();
      }, 300),
    [projectSlug, documentId, documentAnnotationsRef],
  );

  return useCallback(
    (eventType: AnnotationEventType, eventAnnotations: AnnotationBackendJSON[]) => {
      const eventAnnotationIds = eventAnnotations.map(annotation => annotation.id);
      const filteredAnnotations = filterUselessAnnotations(documentAnnotationsRef.current);
      const allAnnotations = applyEventChanges(eventType, filteredAnnotations, eventAnnotations, eventAnnotationIds);

      onUpdateDocumentData({ annotations: allAnnotations });
      operationsStackRef.current[eventType].push(...eventAnnotations);

      updateAnnotations(allAnnotations);
    },
    [updateAnnotations],
  );
};
