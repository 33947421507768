// @see https://orval.dev/guides/custom-axios
import { getSessionToken } from '@descope/react-sdk';
import Axios, { AxiosRequestConfig } from 'axios';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.VITE_API_BASE_URL, // This is the only place we use process.env in runtime
});

export const useCustomAxios =
  <T>(): ((config: AxiosRequestConfig, options?: AxiosRequestConfig) => Promise<T>) =>
  (config: AxiosRequestConfig, options?: AxiosRequestConfig) => {
    const sessionToken = getSessionToken();

    const source = Axios.CancelToken.source();
    const promise = AXIOS_INSTANCE({
      ...config,
      ...options,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${sessionToken}`,
      },
      cancelToken: source.token,
    }).then(({ data }) => data);

    promise.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };

    return promise;
  };

export default useCustomAxios;
