import { ComponentType, ReactElement } from 'react';

type Children = ReactElement | Iterable<ReactElement | false> | false;
type PropsWithChildren<P = unknown> = P & { children: Children };
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type ComponentOrComponentWithProps<T = any> = ComponentType<T> | [ComponentType<T>, T];

export const combineProviders =
  (providers: ComponentOrComponentWithProps[]) =>
  ({ children }: PropsWithChildren) =>
    providers
      .slice()
      .reverse()
      .reduce<Children>((tree, componentOrComponentWithProps) => {
        const [Provider, props] = Array.isArray(componentOrComponentWithProps)
          ? componentOrComponentWithProps
          : [componentOrComponentWithProps];

        return <Provider {...props}>{tree}</Provider>;
      }, children);
