import { Document, DocumentMetadata } from '@/api/generated';
import { createContext } from 'react';

type DocumentsContextValue = {
  documentId?: string;
  documents?: DocumentMetadata[];
  document?: Document;
  isLoadingDocument: boolean;
};

export const DocumentsContext = createContext<DocumentsContextValue | null>(null);
