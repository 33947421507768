import { Components, Theme } from '@mui/material/styles';

const MuiFab: Components<Theme>['MuiFab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      boxShadow: theme.shadows[1],
      backgroundColor: theme.palette.background.default,
      '&.Mui-disabled': {
        boxShadow: theme.shadows[1],
        color: theme.palette.grey[700],
        backgroundColor: `${theme.palette.background.default} !important`,
      },
    }),
  },
};
export default MuiFab;
