import { AnnotationBackendJSON, AnnotationsUnion } from 'pspdfkit';

type AutoCountingAnnotationCustomData = { specialType: 'autoAnnotationParent'; [key: string]: unknown };
type AutoCountingAnnotation = (AnnotationBackendJSON | AnnotationsUnion) & { customData: AutoCountingAnnotationCustomData };

export const isAutoCountingAnnotation = (
  annotation: AnnotationBackendJSON | AnnotationsUnion | undefined,
): annotation is AutoCountingAnnotation => {
  if (!annotation?.customData) return false;

  return annotation.customData.specialType === 'autoAnnotationParent';
};
