import { createContext } from 'react';
import { AnnotationBackendJSON } from 'pspdfkit';
import {
  ComparisonOptions,
  CompareSource,
  JumpToParams,
  SingleDocumentSource,
  UpdatePspdfAnnotations,
} from '@/containers/DocumentEditor/types';
import { type useMultiCountTool } from '@/containers/DocumentEditor/internalHooks/useMultiCountTool';
import { type useMakeToolbarAction } from '@/containers/DocumentEditor/internalHooks/useMakeToolbarAction';
import { type useSubscribeToViewStateChange } from '@/containers/DocumentEditor/internalHooks/useSubscribeToViewStateChange';
import { type useAutoCountTool } from '@/containers/DocumentEditor/internalHooks/useAutoCountTool';
import { type useToolbarCategory } from '@/containers/DocumentEditor/internalHooks/useToolbarCategory';

type EditorAPIContextValue = {
  showAnnotationsEditor: () => void;
  disableEditor: () => void;
  compareDocuments: (firstSource: CompareSource, secondSource: CompareSource, options: ComparisonOptions) => void;
  showSingleDocumentWithAnnotations: (source: SingleDocumentSource, annotations?: AnnotationBackendJSON[]) => void;
  toggleSidebarMode: (sidebarMode: 'THUMBNAILS' | 'DOCUMENT_OUTLINE' | 'FAVORITES') => void;
  setPage: (page: number) => void;
  jumpToBox: (params: JumpToParams) => void;
  updatePspdfAnnotations: UpdatePspdfAnnotations;
  setMultiCountColor: ReturnType<typeof useMultiCountTool>['setMultiCountColor'];
  openMultiCountTool: ReturnType<typeof useMultiCountTool>['openMultiCountTool'];
  setToolbarCategory: ReturnType<typeof useToolbarCategory>['setToolbarCategory'];
  setCategoryInsidePreset: ReturnType<typeof useToolbarCategory>['setCategoryInsidePreset'];
  closeAutoCountTool: ReturnType<typeof useAutoCountTool>['closeAutoCountTool'];
  saveAutoCountAnnotation: ReturnType<typeof useAutoCountTool>['saveAutoCountAnnotation'];
  makeToolbarAction: ReturnType<typeof useMakeToolbarAction>;
  subscribeToViewStateChange: ReturnType<typeof useSubscribeToViewStateChange>;
};

export const EditorAPIContext = createContext<EditorAPIContextValue | null>(null);
