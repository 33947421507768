import { Components, Theme } from '@mui/material/styles';

const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  variants: [
    {
      props: { color: 'primary' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.dark,
        '&.MuiIconButton-colorSecondary': {
          boxShadow: theme.shadows[3],
          '&.MuiIconButton-sizeLarge': { padding: theme.spacing(2) },
          '&:hover': {
            color: theme.palette.text?.primary,
            boxShadow: theme.shadows[1],
          },
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          boxShadow: theme.shadows[5],
        },
      }),
    },
  ],
};
export default MuiIconButton;
