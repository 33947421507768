import { FC } from 'react';
import { IconButton } from '@mui/material';
import Icon from '@/components/Icon/Icon';

interface DialogCloseButtonProps {
  onClick: () => void;
}

const DialogCloseButton: FC<DialogCloseButtonProps> = ({ onClick }) => (
  <IconButton
    color="primary"
    sx={{
      cursor: 'pointer',
      '&, &:hover': {
        position: 'absolute',
        top: 10,
        right: 10,
        p: 0.5,
        boxShadow: 'none',
      },
    }}
    onClick={onClick}
  >
    <Icon name="x" fontSize="small" />
  </IconButton>
);

export default DialogCloseButton;
