export const replaceFractionWithDecimal = (value: string | number) => {
  const matches = String(value)
    .trim()
    .match(/(\d+)\/(\d+)/);

  if (!matches) {
    return Number(value);
  }

  const [, first, second] = matches;
  const result = Number(first) / Number(second);
  return Number.isFinite(result) && !Number.isNaN(result) ? result : 1;
};
