import { TextMatchTransformer } from '@lexical/markdown';
import { $isTextNode, ParagraphNode, TextNode } from 'lexical';
import { ALIGNMENT_TRANSFORMER } from '@/containers/PagesEditor/transformers/alignment.transformer';
import { COLOR_TRANSFORMER } from '@/containers/PagesEditor/transformers/color.transformer';
import { CustomTextMatchTransformer } from '@/containers/PagesEditor/types';

const transformers: CustomTextMatchTransformer[] = [COLOR_TRANSFORMER, ALIGNMENT_TRANSFORMER];
const combinedRegexp = new RegExp(
  transformers
    .map(transformer => transformer.importRegExp?.source)
    .filter(regexp => !!regexp)
    .join('|'),
);

export const TEXT_MATCH_TRANSFORMER: TextMatchTransformer = {
  dependencies: [TextNode, ParagraphNode],
  export: (node, _exportChildren, exportFormat) => {
    if (!$isTextNode(node)) return null;

    return exportFormat(
      node,
      transformers.reduce((result, transformer) => transformer.export(node, result) ?? result, node.getTextContent()),
    );
  },
  importRegExp: combinedRegexp,
  regExp: combinedRegexp,
  replace: (node, match) => {
    let nodes = [node];
    transformers.forEach(transformer => {
      const textMatch = match[0].match(transformer.importRegExp!);
      if (!textMatch || !transformer.replace || !nodes.length) return;
      nodes = transformer.replace(nodes);
    });
  },
  type: 'text-match',
};
