import { ChangeEvent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Status, getGetProjectsQueryKey, useGetProjectsHook, ProjectResponse } from '@/api/generated';
import { usePageTitle } from '@/hooks/usePageTitle';
import Loader from '@/components/Loader/Loader';
import { Box, CircularProgress, Fade, Grid, IconButton, InputAdornment, Stack, Tooltip, useTheme } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useDebounceValue, useIntersectionObserver } from 'usehooks-ts';
import { SORT_DIRECTION } from '@/enums/sort';
import EmptyState from '@/views/Projects/views/EmptyState/EmptyState';
import Project from '@/views/Projects/components/Project/Project';
import ProjectsSkeleton from '@/views/Projects/components/ProjectsSkeleton';
import SortingArrows from '@/views/Projects/components/SortingArrows';
import { useSearchParams } from 'react-router-dom';
import CreateProjectButton from '@/views/Projects/components/CreateProjectButton/CreateProjectButton';
import ViewHeader, { ViewHeaderTab } from '@/components/ViewHeader/ViewHeader';
import { toProjects } from '@/services/linker';
import RoundedInput from '@/components/RoundedInput';
import Icon from '@/components/Icon/Icon';

const PAGE_LIMIT = 10;
const HEADER_KEYS: (keyof ProjectResponse)[] = ['name', 'type', 'delivery_method', 'last_modified', 'owner', 'address'];
const SORT_DIRECTIONS = [SORT_DIRECTION.DESC, SORT_DIRECTION.ASC, SORT_DIRECTION.NONE];
const STATUSES = [Status.active, Status.done];

const Projects = () => {
  const { t } = useTranslation('projects');
  const { palette } = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isIntersecting, ref } = useIntersectionObserver({});
  const { setPageTitle } = usePageTitle();
  const getProjects = useGetProjectsHook();
  const status = (searchParams.get('status') as Status | null) ?? Status.active;
  const searchFilter = searchParams.get('searchFilter') || undefined;
  const sortDir = (searchParams.get('sortDir') as SORT_DIRECTION | null) ?? SORT_DIRECTION.NONE;
  const sortBy = searchParams.get('sortBy') ?? undefined;

  const [debouncedSearchFilter] = useDebounceValue(searchFilter, 100);

  const { data, isLoading, isPending, isFetching, isFetchingNextPage, fetchNextPage, refetch, hasNextPage, dataUpdatedAt } =
    useInfiniteQuery({
      initialPageParam: 0,
      placeholderData: previousData => previousData,
      queryKey: [getGetProjectsQueryKey(), status, sortDir, sortBy, debouncedSearchFilter],
      queryFn: ({ pageParam }: { pageParam: number }) =>
        getProjects({
          statusFilter: status,
          searchFilter: debouncedSearchFilter,
          offset: pageParam,
          limit: PAGE_LIMIT,
          sortDir: sortDir === SORT_DIRECTION.NONE ? SORT_DIRECTION.DESC : sortDir,
          sortBy: sortDir === SORT_DIRECTION.NONE ? 'last_modified' : sortBy,
        }),
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.projects?.[0]?.status !== status) return undefined;
        return (lastPage.projects?.length ?? 0) < PAGE_LIMIT ? undefined : pages.length * PAGE_LIMIT;
      },
    });

  const totalProjects = data?.pages?.[0]?.total ?? 0;
  const projects = useMemo(() => data?.pages.flatMap(page => page.projects!), [dataUpdatedAt]);
  const tabs: ViewHeaderTab[] = STATUSES.map(statusKey => ({
    label: (
      <Tooltip arrow title={t(`tooltips.${statusKey.toLocaleLowerCase()}`)} placement="top">
        <Box component="span">
          {t(`status.${statusKey.toLocaleLowerCase()}`, {
            count: statusKey === status && totalProjects ? totalProjects : 0,
          })}
        </Box>
      </Tooltip>
    ),
    value: statusKey,
    to: toProjects({ status: statusKey, sortDir, sortBy, searchFilter }),
  }));

  useEffect(() => {
    if (!isIntersecting || isFetchingNextPage) return;
    fetchNextPage();
  }, [fetchNextPage, isIntersecting, isFetchingNextPage]);

  useEffect(() => {
    setPageTitle(t('pageTitle'));
  }, [setPageTitle, t]);

  const onSort = (headerKey: string) => {
    const updatedParams = new URLSearchParams(searchParams);

    if (sortBy === headerKey) {
      const currentIndex = SORT_DIRECTIONS.findIndex(dir => dir === sortDir);
      const nextSortDir = SORT_DIRECTIONS[currentIndex + 1] ?? SORT_DIRECTIONS[0];
      updatedParams.set('sortDir', nextSortDir);
      if (nextSortDir === SORT_DIRECTION.NONE) {
        updatedParams.delete('sortBy');
        updatedParams.delete('sortDir');
      }
    } else {
      updatedParams.set('sortDir', SORT_DIRECTION.DESC);
      updatedParams.set('sortBy', headerKey);
    }

    setSearchParams(updatedParams);
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const nextValue = event.target.value;
    const updatedParams = new URLSearchParams(searchParams);
    nextValue.trim() ? updatedParams.set('searchFilter', nextValue) : updatedParams.delete('searchFilter');
    setSearchParams(updatedParams);
  };

  if (isPending) return <Loader id="projects" />;

  const renderList = () => (
    <Stack sx={{ mx: 4, my: 5 }}>
      {!projects?.length && !isLoading ? (
        <EmptyState status={status} searchFilter={searchFilter} />
      ) : (
        <Grid container spacing={3} xs={12} sx={{ m: 0, boxShadow: 2, borderRadius: 3 }}>
          <Grid container item xs={12} sx={{ py: '20px', borderBottom: `1px solid ${palette.grey[200]}` }}>
            {HEADER_KEYS.map((key, index) => (
              <Grid
                item
                xs={2}
                key={key}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 0.5,
                  pr: HEADER_KEYS.length - 1 === index ? 0 : 1,
                  textTransform: 'uppercase',
                  fontSize: 'body2.fontSize',
                  color: palette.grey[400],
                }}
              >
                <Box sx={{ color: palette.grey[700] }}>{t(`list.headers.${key}`)}</Box>
                <Tooltip arrow title={t(`tooltips.sort.${key}`)} placement="top">
                  <IconButton onClick={() => onSort(key)}>
                    <SortingArrows order={sortBy === key ? sortDir : SORT_DIRECTION.NONE} />
                  </IconButton>
                </Tooltip>
              </Grid>
            ))}
          </Grid>

          {projects?.map(project => <Project key={project.slug} project={project} onEditFinish={refetch} onRefetch={refetch} />)}
          {isFetching && <ProjectsSkeleton />}
        </Grid>
      )}
    </Stack>
  );

  const title = (
    <Stack direction="row" alignItems="center" gap={2}>
      {t('header.title')}
      <Fade in={isFetching}>
        <CircularProgress size={25} />
      </Fade>
    </Stack>
  );

  return (
    <Box sx={{ pb: 1 }} data-testid="projects-page">
      <ViewHeader title={title} currentTab={status} tabs={tabs} tabSx={{ pl: 5 }}>
        <Stack direction="row" alignItems="center" gap={3}>
          <RoundedInput
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Stack alignItems="center" sx={{ pl: 2 }}>
                    <Icon name="search" htmlColor={palette.primary.dark} />
                  </Stack>
                </InputAdornment>
              ),
            }}
            sx={{ minWidth: 300, borderRadius: 6 }}
            inputProps={{ sx: { pl: 1, py: 1.4, fontSize: 'body1.fontSize' } }}
            placeholder={t('searchPlaceholder')}
            value={searchFilter}
            onChange={onSearchChange}
          />
          <Box sx={{ width: '100%' }}>
            <Tooltip arrow title={t('tooltips.createProject')} placement="top">
              <CreateProjectButton />
            </Tooltip>
          </Box>
        </Stack>
      </ViewHeader>

      {renderList()}
      {hasNextPage && <Box key={data?.pages?.length ?? 0} ref={ref} />}
    </Box>
  );
};

export default Projects;
