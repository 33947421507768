import { $convertFromMarkdownString, $convertToMarkdownString, MultilineElementTransformer } from '@lexical/markdown';
import { LexicalNode } from 'lexical';
import { PAGES_TRANSFORMERS } from '@/containers/PagesEditor/transformers/index';
import {
  $createLayoutContainerNode,
  $isLayoutContainerNode,
  LayoutContainerNode,
} from '@lexical/playground/nodes/LayoutContainerNode';
import { $createLayoutItemNode, $isLayoutItemNode, LayoutItemNode } from '@lexical/playground/nodes/LayoutItemNode';

const CONTAINER_REGEXP_START = /<layout>/;
const CONTAINER_REGEXP_END = /<\/layout>/;
const COLUMN_REGEXP_START = /<column>/;
const COLUMN_REGEXP_END = /<\/column>/;

export const LAYOUT_TRANSFORMER: MultilineElementTransformer = {
  dependencies: [LayoutContainerNode, LayoutItemNode],
  export: (node: LexicalNode) => {
    if (!$isLayoutContainerNode(node)) return null;

    const content = node
      .getChildren()
      .map(child => {
        if (!$isLayoutItemNode(child)) return;
        return `<column>\n${$convertToMarkdownString(PAGES_TRANSFORMERS, child)}\n</column>\n`;
      })
      .filter(Boolean)
      .join('');

    return `<layout>\n${content}</layout>\n`;
  },
  regExpStart: CONTAINER_REGEXP_START,
  regExpEnd: CONTAINER_REGEXP_END,
  replace: (parentNode, _1, _2, _3, linesInBetween) => {
    if (!linesInBetween) return;

    let isColumn = false;
    const columnsLines: Array<string[]> = [];
    for (const line of linesInBetween) {
      if (line.match(COLUMN_REGEXP_END)) {
        isColumn = false;
        continue;
      }
      if (line.match(COLUMN_REGEXP_START)) {
        isColumn = true;
        columnsLines.push([]);
        continue;
      }

      if (isColumn) columnsLines[columnsLines.length - 1].push(line);
    }

    const containerNode = $createLayoutContainerNode('1fr '.repeat(columnsLines.length).trim());
    const columnsNodes = columnsLines.map(columnLines => {
      const columnNode = $createLayoutItemNode();
      $convertFromMarkdownString(columnLines.join('\n'), PAGES_TRANSFORMERS, columnNode);
      return columnNode;
    });
    containerNode.append(...columnsNodes);
    parentNode.append(containerNode);
  },
  type: 'multiline-element',
};
