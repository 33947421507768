import { FC, useEffect, useMemo, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetProjectsIdDocumentsDocumentId } from '@/api/generated';
import { useProject } from '@/views/Project/hooks/useProject';
import { DocumentsContext } from '@/views/Project/contexts/DocumentsContext';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

interface DocumentsProviderProps {
  children: ReactNode;
}

const DocumentsProvider: FC<DocumentsProviderProps> = ({ children }) => {
  const { t } = useTranslation('project');
  const [searchParams, setSearchParams] = useSearchParams();
  const { projectSlug, project } = useProject();
  const { defaultDocumentId } = project;
  const documentId = searchParams.get('documentId');
  const documents = project.documents;
  const isDocumentInProject = !!project?.documents?.find(doc => doc._id === documentId);

  const { data: document, isLoading: isLoadingDocument } = useGetProjectsIdDocumentsDocumentId(
    projectSlug,
    { document_id: documentId! },
    { query: { enabled: !!projectSlug && !!documentId && isDocumentInProject, gcTime: 0 } },
  );

  useEffect(() => {
    if (documentId && isDocumentInProject) return;

    const nextDocumentId = defaultDocumentId || documents?.[0]?._id;
    if (!documentId && !nextDocumentId) {
      enqueueSnackbar(t('noDocumentError'), { variant: 'error' });
      return;
    }

    setSearchParams(
      prevParams => {
        const nextParams = new URLSearchParams(prevParams);
        if (documentId && !isDocumentInProject) {
          nextParams.delete('documentId');
          enqueueSnackbar(t('noDocumentError'), { variant: 'error' });
        }
        if ((!documentId || !isDocumentInProject) && nextDocumentId) {
          nextParams.set('documentId', nextDocumentId);
        }
        return nextParams;
      },
      { replace: true },
    );
  }, [documentId, defaultDocumentId, projectSlug, documents, isDocumentInProject]);

  const value = useMemo(
    () => ({ documentId: documentId || undefined, documents, document, isLoadingDocument }),
    [documentId, documents, document, isLoadingDocument],
  );

  return <DocumentsContext.Provider value={value}>{children}</DocumentsContext.Provider>;
};

export default DocumentsProvider;
