import { createContext } from 'react';

type EmptyDocumentProjectContextValue = {
  forceDropZoneView: boolean;
  setForceDropZoneView: (nextState: boolean) => void;
};

export const EmptyDocumentProjectContext = createContext<EmptyDocumentProjectContextValue>({
  forceDropZoneView: false,
  setForceDropZoneView: () => {},
});
