import { BaseSyntheticEvent, forwardRef, KeyboardEvent, ReactElement } from 'react';
import { Box, type InputProps, SxProps } from '@mui/material';
import { Control, Controller, FieldError } from 'react-hook-form';
import { Key } from 'ts-key-enum';
import RoundedMentionInput from '@/components/RoundedMentionInput/RoundedMentionInput';
import { SuggestionDataItem } from '@/utils/getMentionTypeOptions';

interface FormMentionsInputSubmitProps {
  error?: FieldError;
  handleSubmit?: (e?: BaseSyntheticEvent<object, unknown, unknown> | undefined) => Promise<void>;
  placeholder?: string;
  InputProps?: InputProps;
  sx?: SxProps;
  disabled?: boolean;
  documents?: SuggestionDataItem[];
  currentValue?: string;
  control?: unknown;
  fieldName: string;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  tools?: SuggestionDataItem[];
}

const FormMentionsInputSubmit = forwardRef<HTMLTextAreaElement | null, FormMentionsInputSubmitProps>(
  ({ handleSubmit, documents, control, fieldName, tools = [], ...props }, ref) => {
    const onSubmit = (event: KeyboardEvent<HTMLTextAreaElement> | KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== Key.Enter || event.shiftKey) return;
      handleSubmit && handleSubmit();
    };

    return (
      <Box component="form" onSubmit={handleSubmit} sx={{ zIndex: 1000, position: 'relative' }}>
        <Controller
          name={fieldName}
          control={control as Control | undefined}
          render={({ field }) => (
            <RoundedMentionInput
              {...field}
              {...props}
              ref={e => {
                field.ref(e);
                /* @ts-expect-error */
                ref && (ref.current = e);
              }}
              onKeyDown={onSubmit}
              tools={tools}
              documents={documents}
            />
          )}
        />
      </Box>
    );
  },
);

export default FormMentionsInputSubmit;
