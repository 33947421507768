import { useEffect, useState } from 'react';
import { ConstructingEquipmentPiece, UnitType } from '@/api/generated';
import { AnnotationsUnion, Instance, List } from 'pspdfkit';

type Params = {
  instance?: Instance;
  annotationsByType: Record<UnitType, ConstructingEquipmentPiece[]>;
};

export const useSelectedAnnotations = ({ instance, annotationsByType }: Params) => {
  const [selectedAnnotations, setSelectedAnnotations] = useState<AnnotationsUnion[] | null>(null);
  const [selectedEquipmentItems, setSelectedEquipmentItems] = useState<ConstructingEquipmentPiece[] | null>(null);

  useEffect(() => {
    if (!instance) return;

    const onSelectionChange = (selectedAnnotationsImmutable?: List<AnnotationsUnion>) => {
      const annotations = (selectedAnnotationsImmutable?.toJSON() ?? []) as unknown as AnnotationsUnion[];

      if (annotations.length === 0) {
        setSelectedEquipmentItems(null);
        setSelectedAnnotations(null);
        return;
      }

      // If annotations doesn't have groupAnnotationKey it means it shouldn't have it.
      const hasNoneGroupAnnotation = annotations.some(annotation => !(annotation.get('customData') ?? {}).groupAnnotationKey);

      if (hasNoneGroupAnnotation) {
        setSelectedEquipmentItems(null);
        setSelectedAnnotations(null);
        return;
      }

      // When we have annotations that is in different equipment type we don't need to show category select.
      const annotationsIds = annotations.map(annotation => annotation.get('id'));
      let equipmentItems: ConstructingEquipmentPiece[] | null = null;
      for (const [, items] of Object.entries(annotationsByType)) {
        const intersection = items.filter(item => annotationsIds.includes(item._id));
        const allInOneGroup = intersection.length === annotationsIds.length;

        if (allInOneGroup) {
          equipmentItems = intersection;
          break;
        }

        if (intersection.length !== 0) break;
      }

      setSelectedAnnotations(equipmentItems ? annotations : null);
      setSelectedEquipmentItems(equipmentItems);
    };

    instance.addEventListener('annotationSelection.change', onSelectionChange);

    return () => {
      instance.removeEventListener('annotationSelection.change', onSelectionChange);
    };
  }, [instance, annotationsByType]);

  return {
    selectedAnnotations,
    selectedEquipmentItems,
  };
};
