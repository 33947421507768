import PSPDFKit from 'pspdfkit';
import { MutableRefObject, useCallback } from 'react';
import { usePspdfkit } from '@/containers/DocumentEditor/internalHooks/usePspdfkit';
import { CompareSourcesConfig } from '@/containers/DocumentEditor/types';
import { useGetProjectsIdDocumentsDocumentId } from '@/api/generated';
import { useProject } from '@/views/Project/hooks/useProject';

type Params = {
  isEnabled?: boolean;
  containerRef: MutableRefObject<HTMLDivElement | null>;
  secondContainerRef: MutableRefObject<HTMLDivElement | null>;
  compareSources?: CompareSourcesConfig | null;
};

const URL_STORE_TIME = 1000 * 60 * 20; // 20 minutes

export const useComparePspdf = ({ isEnabled, containerRef, secondContainerRef, compareSources }: Params) => {
  const { projectSlug } = useProject();
  const { firstSource, secondSource, autoCompare, splitScreen } = compareSources ?? {};

  const { data: firstDocument, isLoading: firstDocumentIsLoading } = useGetProjectsIdDocumentsDocumentId(
    projectSlug,
    { document_id: firstSource ? firstSource.documentId! : 'fake' },
    { query: { enabled: isEnabled && !!firstSource, staleTime: URL_STORE_TIME } },
  );

  const { data: secondDocument, isLoading: secondDocumentIsLoading } = useGetProjectsIdDocumentsDocumentId(
    projectSlug,
    { document_id: secondSource ? secondSource.documentId! : 'fake' },
    { query: { enabled: isEnabled && !!secondSource, staleTime: URL_STORE_TIME } },
  );

  const isCompareEnabled = !!isEnabled && Boolean(firstDocument && secondDocument && compareSources);
  const firstKey = firstDocument && compareSources ? `${firstDocument._id}_${firstSource?.page}` : undefined;
  const secondKey = secondDocument && compareSources ? `${secondDocument._id}_${secondSource?.page}` : undefined;
  const key = firstKey && secondKey ? `${firstKey}_${secondKey}_${autoCompare}` : undefined;
  const firstUrl = firstDocument?.urls && firstSource ? firstDocument.urls[firstSource.page] : undefined;
  const secondUrl = secondDocument?.urls && secondSource ? secondDocument.urls[secondSource.page] : undefined;

  const { isLoading: isFirstLoading, unload: unloadFirst } = usePspdfkit({
    isEnabled: isCompareEnabled,
    key: splitScreen ? firstKey : key,
    containerRef,
    url: firstUrl,
    instanceConfig: {
      initialViewState: PSPDFKit.viewStateFromOpenParameters(new PSPDFKit.ViewState({ showToolbar: false })),
    },
    onLoad: async (instance, controller) => {
      if (!instance || !firstUrl || !secondUrl || splitScreen) {
        return;
      }

      try {
        const source = await fetch(secondUrl, { signal: controller.signal }).then(response => response.arrayBuffer());
        instance.setDocumentComparisonMode({
          documentA: { source: 'USE_OPEN_DOCUMENT', pageIndex: 0 },
          documentB: { source: source, pageIndex: 0 },
          autoCompare: !!autoCompare,
          strokeColors: {
            documentA: PSPDFKit.Color.BLUE,
            documentB: PSPDFKit.Color.RED,
          },
        });
      } catch (error) {
        console.error('Error while loading source', error);
        /* empty */
      }
    },
  });

  const { isLoading: isSecondLoading, unload: unloadSecond } = usePspdfkit({
    isEnabled: isCompareEnabled && splitScreen,
    key: secondKey,
    containerRef: secondContainerRef,
    url: secondUrl,
    instanceConfig: {
      initialViewState: PSPDFKit.viewStateFromOpenParameters(
        new PSPDFKit.ViewState({ showToolbar: false, enableAlwaysScrollToZoom: false }),
      ),
    },
  });

  const isLoading = splitScreen ? isFirstLoading && isSecondLoading : isFirstLoading;
  const unload = useCallback(() => {
    unloadFirst();
    unloadSecond();
  }, [unloadFirst, unloadSecond]);

  return {
    comparedDocuments: firstDocument && secondDocument ? { firstDocument, secondDocument } : undefined,
    isLoading: firstDocumentIsLoading || secondDocumentIsLoading || isLoading,
    unload,
  };
};
