import { Components, Theme } from '@mui/material/styles';

const MuiSlider: Components<Theme>['MuiSlider'] = {
  styleOverrides: {
    root: { height: 2, borderRadius: 0 },
    mark: ({ theme }) => ({
      height: 20,
      backgroundColor: theme.palette.grey[700],
    }),
    markLabel: ({ theme }) => ({
      color: theme.palette.grey[700],
    }),
    track: ({ theme }) => ({
      backgroundColor: theme.palette.grey[700],
    }),
    rail: ({ theme }) => ({
      opacity: 1,
      backgroundColor: theme.palette.grey[700],
    }),
    thumb: ({ theme }) => ({
      width: 25,
      height: 25,
      padding: theme.spacing(0.3),
      backgroundColor: theme.palette.background.default,
    }),
  },
};
export default MuiSlider;
