import { ElementTransformer } from '@lexical/markdown';
import { $createPageBreakNode, $isPageBreakNode, PageBreakNode } from '@lexical/playground/nodes/PageBreakNode';
import { LexicalNode } from 'lexical';

export const PAGE_BREAK_TRANSFORMER: ElementTransformer = {
  dependencies: [PageBreakNode],
  export: (node: LexicalNode) => ($isPageBreakNode(node) ? '<div style="page-break-after: always;"></div>' : null),
  regExp: /<div style="page-break-after: always;"><\/div>/,
  replace: parentNode => {
    parentNode.append($createPageBreakNode());
  },
  type: 'element',
};
