const downloadFile = (buffer: BlobPart, filename: string, type: string) => {
  const blob = new Blob([buffer], { type });
  const objectUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = objectUrl;
  a.style.display = 'none';
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(objectUrl);
  document.body.removeChild(a);
};

export default downloadFile;
