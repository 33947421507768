import { Box, Button, CircularProgress, Dialog, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { ProjectFull, useGetFilesystemHook } from '@/api/generated';
import DocumentUpload from '@/views/Projects/components/ProjectFormDialog/components/DocumentUpload';
import ProjectForm from '@/views/Projects/components/ProjectFormDialog/components/ProjectForm';
import { STATUS } from '@/utils/enums';
import { useFileSystemTree } from '@/views/Projects/components/ProjectFormDialog/hooks/useFileSystemTree';
import { useProjectForm } from '@/views/Projects/components/ProjectFormDialog/hooks/useProjectForm';
import { updateProjectCache } from '@/utils/updateProjectCache';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';

interface ProjectFormDialogProps {
  isOpened: boolean;
  initialProject?: ProjectFull;
  onClose: (project?: ProjectFull) => void;
  onAfterSave: (project: ProjectFull) => void;
}

const ProjectFormDialog: FC<ProjectFormDialogProps> = ({ isOpened, initialProject, onClose, onAfterSave }) => {
  const queryClient = useQueryClient();
  const { palette } = useTheme();
  const { t } = useTranslation('projectUpdate');
  const { showConfirmDialog } = useConfirmDialog();
  const uploadingFilesPromisesRef = useRef<Promise<unknown>[]>([]);

  const { form, isSaving, resetFormValues, onSubmit } = useProjectForm({
    initialProject,
    onAfterSave,
  });
  const projectSlug = form.watch('slug');
  const getFileSystem = useGetFilesystemHook();

  const handleClose = useCallback(async () => {
    if (uploadingFilesPromisesRef.current.length) {
      const result = await showConfirmDialog({
        title: t('uploadFiles.abortUploadDialog.title'),
        confirm: t('uploadFiles.abortUploadDialog.abort'),
        cancel: t('uploadFiles.abortUploadDialog.cancel'),
      });
      if (!result) return;

      for (const promise of uploadingFilesPromisesRef.current) {
        promise.cancel();
      }
    }
    onClose();
  }, [onClose, showConfirmDialog, t]);

  const {
    loadFileSystem,
    fileSystemNodes,
    importDriveFiles,
    uploadFiles,
    onRename,
    onMove,
    onFolderCreate,
    onDelete,
    onFileOpen,
    onDocumentConvertToPage,
  } = useFileSystemTree({
    initialProject,
    getFormValues: form.getValues,
    onClose: handleClose,
    onProjectCreate: createdProject => {
      form.setValue('slug', createdProject.slug);
      form.setValue('name', createdProject.name!);
    },
    uploadingFilesPromisesRef,
  });

  const isUploading = Object.entries(fileSystemNodes).some(([, node]) => node.status === STATUS.LOADING);

  useEffect(() => {
    if (!isOpened) return;
    resetFormValues(initialProject);
    loadFileSystem(initialProject);
  }, [isOpened, initialProject]);

  useEffect(() => {
    const slug = form.getValues('slug');
    if (!slug || isOpened) return;

    (async () => {
      // We update file system here to keep it in sync for documents in navigation.
      const fileSystem = await getFileSystem(slug);
      updateProjectCache({ queryClient, projectSlug: slug }, prevProject => {
        if (!prevProject) return;
        return { ...prevProject, filesystem: fileSystem };
      });
    })();
  }, [isOpened, initialProject]);

  return (
    <Dialog
      component="form"
      fullWidth
      open={isOpened}
      maxWidth="md"
      onClose={() => handleClose()}
      onSubmit={onSubmit}
      PaperProps={{ sx: { backgroundColor: palette.background.default, height: '90%' } }}
    >
      <FormProvider {...form}>
        {projectSlug && (
          <Button variant="contained" sx={{ position: 'absolute', top: 20, right: 20, zIndex: 1, py: 0.1, borderRadius: 99 }}>
            {isUploading || isSaving ? <CircularProgress size={12} /> : t('details.save')}
          </Button>
        )}
        <Box sx={{ p: 3 }}>
          <ProjectForm />
          <DocumentUpload
            fileSystemNodes={fileSystemNodes}
            importDriveFiles={importDriveFiles}
            uploadFiles={uploadFiles}
            onRename={onRename}
            onMove={onMove}
            onFolderCreate={onFolderCreate}
            onDelete={onDelete}
            onFileOpen={onFileOpen}
            onDocumentConvertToPage={onDocumentConvertToPage}
          />
        </Box>
      </FormProvider>
    </Dialog>
  );
};

export default ProjectFormDialog;
