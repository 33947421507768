import { IInteractionMode, Instance } from 'pspdfkit';
import nextTick from '@/services/nextTick';

type Params = {
  instance: Instance;
};

export const keepSelectingToolForPerimeterAndPolygon = ({ instance }: Params) => {
  let createdMeasurementType: IInteractionMode | null = null;
  instance.addEventListener('annotations.create', createdAnnotations => {
    const annotation = createdAnnotations.get(0);
    if (
      annotation?.isMeasurement?.() &&
      instance.viewState.interactionMode &&
      ['PERIMETER', 'POLYGON_AREA'].includes(instance.viewState.interactionMode)
    ) {
      createdMeasurementType = instance.viewState.interactionMode;
    }
  });

  instance.addEventListener('annotationSelection.change', () => {
    if (createdMeasurementType) {
      const preset = createdMeasurementType === 'PERIMETER' ? 'perimeter' : 'polygon-area';
      const newInteractionMode = createdMeasurementType;
      nextTick(() => {
        createdMeasurementType = null;
        instance.setCurrentAnnotationPreset(preset);
        instance.setViewState(currentViewState => currentViewState.set('interactionMode', newInteractionMode));
      });
    }
  });
};
