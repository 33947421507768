import { FC } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextInput from '@/components/TextInput/TextInput';
import DeleteIcon from '@/assets/delete.svg?react';
import { ProjectFormValue } from '../types';
import Icon from '@/components/Icon/Icon';

interface StakeholdersProps {
  control: Control<ProjectFormValue>;
}

const Stakeholders: FC<StakeholdersProps> = ({ control }) => {
  const { palette, shape, typography, shadows, spacing } = useTheme();
  const { t } = useTranslation('projectUpdate');
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'stakeholders',
  });

  const isSingleStakeholder = fields.length === 1;

  return (
    <>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="body1" fontWeight="fontWeightMedium" sx={{ color: palette.controls.text }}>
          {t('stakeholders.header')}
        </Typography>
        <IconButton
          color="primary"
          sx={{
            minWidth: 'unset',
            p: 0.125,
            '&, &:hover': {
              backgroundColor: 'transparent',
              border: `1px solid ${palette.primary.dark}`,
            },
          }}
          onClick={() => append({ name: '', role: '', company: '', email: '', phone: '' })}
        >
          <Icon name="plus" fontSize="small" />
        </IconButton>
      </Stack>
      <Stack gap={2}>
        {fields.map((field, index) => (
          <Stack
            gap={1}
            key={field.id}
            sx={{
              position: 'relative',
              px: 1,
              py: 1.25,
              border: `1px solid ${palette.grey[200]}`,
              borderRadius: shape.borderRadius,
            }}
          >
            <Stack direction="row" gap={2}>
              <TextInput
                label={t('stakeholders.name')}
                placeholder={t('stakeholders.placeholders.name')}
                control={control}
                id={`projectUpdate-stakeholders.${index}.name`}
                name={`stakeholders.${index}.name`}
                labelSx={{ fontWeight: typography.fontWeightRegular, fontSize: typography.body2 }}
                translate="projectUpdate"
              />
              <TextInput
                label={t('stakeholders.title')}
                placeholder={t('stakeholders.placeholders.title')}
                control={control}
                id={`projectUpdate-stakeholders.${index}.role`}
                name={`stakeholders.${index}.role`}
                labelSx={{ fontWeight: typography.fontWeightRegular, fontSize: typography.body2 }}
                translate="projectUpdate"
              />
            </Stack>
            <Stack direction="row" gap={2}>
              <TextInput
                label={t('stakeholders.company')}
                placeholder={t('stakeholders.placeholders.company')}
                control={control}
                id={`projectUpdate-stakeholders.${index}.company`}
                name={`stakeholders.${index}.company`}
                labelSx={{ fontWeight: typography.fontWeightRegular, fontSize: typography.body2 }}
                translate="projectUpdate"
              />
              <TextInput
                label={t('stakeholders.email')}
                placeholder={t('stakeholders.placeholders.email')}
                control={control}
                id={`projectUpdate-stakeholders.${index}.email`}
                name={`stakeholders.${index}.email`}
                labelSx={{ fontWeight: typography.fontWeightRegular, fontSize: typography.body2 }}
                translate="projectUpdate"
              />
            </Stack>
            <Stack sx={{ maxWidth: `calc(50% - ${spacing})` }}>
              <TextInput
                label={t('stakeholders.phoneNumber')}
                placeholder={t('stakeholders.placeholders.phoneNumber')}
                control={control}
                id={`projectUpdate-stakeholders.${index}.phone`}
                name={`stakeholders.${index}.phone`}
                labelSx={{ fontWeight: typography.fontWeightRegular, fontSize: typography.body2 }}
                translate="projectUpdate"
              />
            </Stack>
            {!isSingleStakeholder && (
              <IconButton
                color="primary"
                sx={{
                  minWidth: 'unset',
                  boxShadow: 'none',
                  position: 'absolute',
                  top: 10,
                  right: 8,
                  color: palette.darkPurple.light,
                  '&, &:hover': {
                    backgroundColor: 'transparent',
                    p: 0,
                  },
                  '&:hover': {
                    boxShadow: shadows[5],
                  },
                }}
                onClick={() => !isSingleStakeholder && remove(index)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Stack>
        ))}
      </Stack>
    </>
  );
};
export default Stakeholders;
