import { TypographyOptions, TypographyStyleOptions } from '@mui/material/styles/createTypography';

interface DefaultTypographyOptions extends TypographyOptions {
  base: TypographyStyleOptions;
  body3: TypographyStyleOptions;
  body4: TypographyStyleOptions;
  icons: TypographyStyleOptions;
}

const typography: DefaultTypographyOptions = {
  fontFamily: ['Lato', 'sans-serif'].join(','),
  h1: {
    fontSize: '2.5rem',
    fontWeight: 700,
  },
  h2: {
    fontSize: '1.65rem',
    fontWeight: 700,
  },
  h3: {
    fontSize: '1.25rem',
    fontWeight: 400,
  },
  h4: {
    fontSize: '1.125rem',
  },
  base: {
    fontSize: '1rem',
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.25,
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  body3: {
    fontSize: '0.75rem',
    fontWeight: 400,
  },
  body4: {
    fontSize: '0.625rem',
    fontWeight: 400,
  },
  icons: {
    fontSize: '1.125rem',
  },
  fontWeightRegular: 400,
  fontWeightMedium: 700,
  fontWeightBold: 900,
};

export default typography;
