import { FC, ReactNode, useRef, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import ProjectFormDialog from '@/views/Projects/components/ProjectFormDialog/ProjectFormDialog';
import { ProjectFormDialogContext, ProjectFormDialogContextValue } from '@/contexts/ProjectFromDialogContext';
import { ProjectFull, useGetProjectsIdHook } from '@/api/generated';
import Loader from '@/components/Loader/Loader';

interface ProjectFormDialogProviderProps {
  children: ReactNode;
}

type Result = false | ProjectFull;

export const ProjectFormDialogProvider: FC<ProjectFormDialogProviderProps> = ({ children }) => {
  const { t } = useTranslation('common');
  const [isOpened, setIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState<ProjectFull>();
  const resolveRef = useRef<(result: Result) => void>(() => false);
  const getProject = useGetProjectsIdHook();

  const contextValue: ProjectFormDialogContextValue = {
    showProjectFormDialog: async slug => {
      if (slug) {
        setIsLoading(true);
        try {
          const nextProject = await getProject(slug);
          setProject(nextProject);
        } catch (error) {
          enqueueSnackbar(t('projectFormDialog.loadingError'), { variant: 'error' });
          console.error('Error while loading project for dialog', error);
        }
        setIsLoading(false);
      }
      setIsOpened(true);
      return new Promise<Result>(resolve => (resolveRef.current = resolve));
    },
  };

  const onDialogClose = (result: false | ProjectFull) => {
    resolveRef.current(result);
    setProject(undefined);
    setIsOpened(false);
  };

  return (
    <ProjectFormDialogContext.Provider value={contextValue}>
      {isLoading ? (
        <Loader id="use-project" />
      ) : (
        <ProjectFormDialog
          isOpened={isOpened}
          initialProject={project}
          onClose={() => onDialogClose(false)}
          onAfterSave={onDialogClose}
        />
      )}

      {children}
    </ProjectFormDialogContext.Provider>
  );
};
