import { FC, ReactNode, useEffect, useState } from 'react';
import { EmptyDocumentProjectContext } from '@/views/Project/contexts/EmptyDocumentProjectContext';
import { PROJECT_ROUTER_IDS } from '@/services/linker';
import useRouteId from '@/hooks/useRouteId';

interface EmptyDocumentProjectProviderProps {
  children: ReactNode;
}

const EmptyDocumentProjectProvider: FC<EmptyDocumentProjectProviderProps> = ({ children }) => {
  const routeId = useRouteId();
  const [forceDropZoneView, setForceDropZoneView] = useState(false);

  useEffect(() => {
    if (!PROJECT_ROUTER_IDS.includes(routeId)) {
      setForceDropZoneView(false);
    }
  }, [routeId]);

  return (
    <EmptyDocumentProjectContext.Provider value={{ forceDropZoneView, setForceDropZoneView }}>
      {children}
    </EmptyDocumentProjectContext.Provider>
  );
};

export default EmptyDocumentProjectProvider;
