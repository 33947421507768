import { useCallback, useEffect, useState } from 'react';
import { CountingMode } from '@/containers/DocumentEditor/types';
import { CustomPreset } from '@/containers/DocumentEditor/utils/presets';
import { AnnotationsUnion, Instance, List } from 'pspdfkit';
import { isAutoCountingAnnotation } from '@/containers/DocumentEditor/utils/isAutoCountingAnnotation';

type Params = {
  instance?: Instance;
  mode: CountingMode;
  setMode: (mode: CountingMode) => void;
  setInitialMode: () => void;
};

export const useAutoCountTool = ({ instance, mode, setMode, setInitialMode }: Params) => {
  const [autoCountingTempAnnotation, setAutoCountingTempAnnotation] = useState<AnnotationsUnion | null>(null);

  const closeAutoCountTool = useCallback(() => {
    autoCountingTempAnnotation && instance?.delete(autoCountingTempAnnotation);
    setAutoCountingTempAnnotation(null);
    setInitialMode();
  }, [instance, autoCountingTempAnnotation]);

  const openAutoCountTool = useCallback(() => {
    if (!instance) return;

    instance.setCurrentAnnotationPreset(CustomPreset.autoCounting);
    instance.setViewState(viewState => viewState.set('keepSelectedTool', false).set('interactionMode', 'SHAPE_RECTANGLE'));
    setMode(CountingMode.AUTO_COUNTING);
  }, [instance, mode]);

  useEffect(() => {
    if (!instance || mode !== CountingMode.AUTO_COUNTING) return;

    const handleAnnotationsCreate = (annotations: List<AnnotationsUnion>) => {
      const annotation = annotations.get(0);
      if (isAutoCountingAnnotation(annotation)) setAutoCountingTempAnnotation(annotation);
    };

    instance.addEventListener('annotations.create', handleAnnotationsCreate);

    return () => {
      instance.removeEventListener('annotations.create', handleAnnotationsCreate);
    };
  }, [instance, mode]);

  const saveAutoCountAnnotation = useCallback(
    (pages: number[], sensitivity: number) => {
      if (!instance || !pages.length || !autoCountingTempAnnotation) return;

      instance.create(
        autoCountingTempAnnotation
          .set('customData', { specialType: 'autoAnnotationParent', runOnPages: pages, sensitivity })
          .delete('id')
          .delete('pdfObjectId')
          .delete('name'),
      );
      closeAutoCountTool();
    },
    [instance, autoCountingTempAnnotation, closeAutoCountTool],
  );

  return { autoCountingTempAnnotation, saveAutoCountAnnotation, openAutoCountTool, closeAutoCountTool };
};
