import { Components, Theme } from '@mui/material/styles';

const MuiRadio: Components<Theme>['MuiRadio'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.Mui-checked': {
        color: theme.palette.primary.dark,
      },
    }),
  },
};
export default MuiRadio;
