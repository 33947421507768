export const EXPANDED_OFFSET = 0.1;

export const getPositionAndSizeForExpanded = () => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const horizontalPadding = window.innerWidth * (EXPANDED_OFFSET / 2);
  const verticalPadding = window.innerHeight * (EXPANDED_OFFSET / 2);

  const nextRndWindowSize = {
    width: screenWidth - horizontalPadding * 2,
    height: screenHeight - verticalPadding * 2,
  };
  const nextRndPosition = {
    x: horizontalPadding,
    y: horizontalPadding,
  };

  return { position: nextRndPosition, size: nextRndWindowSize };
};
