import { Instance, IScrollMode, ViewState } from 'pspdfkit';
import { useEffect, useRef, useState } from 'react';
import isMeasurementMode from '@/containers/DocumentEditor/utils/isMeasurementMode';

type Params = {
  instance?: Instance;
};

export const useMeasurementDrag = ({ instance }: Params) => {
  const [scrollMode, setScrollMode] = useState<IScrollMode>();
  const [pageIndex, setPageIndex] = useState(0);
  const mouseIsPressed = useRef(false);
  const interval = useRef<ReturnType<typeof setInterval>>();
  const direction = useRef({ x: 0, y: 0 });

  useEffect(() => {
    if (!instance) return;

    setScrollMode(instance.viewState.scrollMode);
    const onViewStateChange = (viewState: ViewState) => {
      setScrollMode(viewState.scrollMode);
      setPageIndex(viewState.currentPageIndex);
    };
    instance.addEventListener('viewState.change', onViewStateChange);

    return () => {
      instance.removeEventListener('viewState.change', onViewStateChange);
    };
  }, [instance]);

  useEffect(() => {
    const onMouseDown = () => (mouseIsPressed.current = true);
    const onMouseUp = () => (mouseIsPressed.current = false);

    document.body.addEventListener('mousedown', onMouseDown);
    document.body.addEventListener('mouseup', onMouseUp);

    return () => {
      document.body.removeEventListener('mousedown', onMouseDown);
      document.body.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  useEffect(() => {
    if (!instance) return;

    const scrollArea = instance.contentDocument.querySelector('.PSPDFKit-Scroll');
    if (!scrollArea) return;

    const recalcDirection = (event: MouseEvent) => {
      if (!mouseIsPressed.current) return;

      const bounds = scrollArea.getBoundingClientRect();

      if (event.clientX <= bounds.x + 10) {
        direction.current.y = -30;
      } else if (event.clientX > bounds.x + bounds.width - 10) {
        direction.current.y = 30;
      }
      if (event.clientY < bounds.y + 10) {
        direction.current.x = -30;
      } else if (event.clientY > bounds.y + bounds.height - 10) {
        direction.current.x = 30;
      }
    };

    const onMouseEnter = () => clearInterval(interval.current);
    const onMouseLeave = (event: MouseEvent) => {
      if (!isMeasurementMode(instance.viewState.interactionMode)) return;

      direction.current.y = 0;
      direction.current.x = 0;
      recalcDirection(event);

      interval.current = setInterval(() => {
        scrollArea.scrollLeft = scrollArea.scrollLeft + direction.current.y;
        scrollArea.scrollTop = scrollArea.scrollTop + direction.current.x;
      }, 100);
    };

    scrollArea.addEventListener('mouseenter', onMouseEnter);
    scrollArea.addEventListener('mouseleave', onMouseLeave as never);
    document.body.addEventListener('mousemove', recalcDirection as never);

    return () => {
      scrollArea.removeEventListener('mouseenter', onMouseEnter);
      scrollArea.removeEventListener('mouseleave', onMouseLeave as never);
      document.body.removeEventListener('mousemove', recalcDirection as never);
      clearInterval(interval.current);
    };
  }, [instance, scrollMode, pageIndex]);
};
