import { Tag } from '@/api/generated';
import { Chip, ChipProps, useTheme } from '@mui/material';
import { FC } from 'react';

interface TagChipProps extends ChipProps {
  tag: Tag;
}

const TagChip: FC<TagChipProps> = ({ tag, sx = {}, ...props }) => {
  const { palette, typography } = useTheme();

  return (
    <Chip
      label={tag.name}
      variant="outlined"
      key={tag.name}
      sx={{
        fontSize: typography.body4.fontSize,
        color: palette.primary.dark,
        fontWeight: typography.fontWeightMedium,
        backgroundColor: tag.color,
        py: 0.7,
        px: 0,
        height: 'unset',
        position: 'relative',
        border: 0,
        borderRadius: 2,
        ...sx,
      }}
      {...props}
    />
  );
};

export default TagChip;
