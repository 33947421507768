import { useCallback } from 'react';
import { Instance } from 'pspdfkit';
import { toolbarItems } from '@/containers/DocumentEditor/utils/toolbarItems';
import { MakeToolbarAction, SetToolCategoryOptions } from '@/containers/DocumentEditor/types';
import { EquipmentDescriptionSearch } from '@/api/generated';

type Params = {
  instance?: Instance;
  documentFilename?: string;
  openAutoCountTool: () => void;
  openMultiCountTool: (category?: EquipmentDescriptionSearch | null) => void;
  setToolbarCategory: (
    category: EquipmentDescriptionSearch | null,
    options: SetToolCategoryOptions,
  ) => EquipmentDescriptionSearch | null | undefined;
  setMultiCountColor: (color: string) => void;
};

export const useMakeToolbarAction = ({
  instance,
  documentFilename,
  openAutoCountTool,
  openMultiCountTool,
  setToolbarCategory,
  setMultiCountColor,
}: Params): MakeToolbarAction =>
  useCallback(
    (key, params) => {
      if (!instance) return;

      toolbarItems[key].action(
        { instance, setToolbarCategory, setMultiCountColor },
        {
          openAutoCountTool,
          openMultiCountTool,
          documentFilename: documentFilename!,
          ...params,
        },
      );
    },
    [instance, openAutoCountTool, openMultiCountTool, setToolbarCategory, documentFilename],
  );
