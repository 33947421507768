import { Box, useTheme } from '@mui/material';
import { FC } from 'react';

interface StatusDotProps {
  isDocumentReady: boolean;
}

const StatusDot: FC<StatusDotProps> = ({ isDocumentReady }) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        width: '6px',
        minWidth: '6px',
        height: '6px',
        borderRadius: '999px',
        backgroundColor: isDocumentReady ? palette.feedback.light : palette.feedback.main,
      }}
    />
  );
};

export default StatusDot;
