import { Components } from '@mui/material/styles';

const MuiPopper: Components['MuiPopper'] = {
  styleOverrides: {
    root: {
      zIndex: 100,
    },
  },
};
export default MuiPopper;
