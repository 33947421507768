import { FC, useEffect, useRef, useState } from 'react';
import { Box, IconButton, IconButtonProps, InputAdornment, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Icon from '@/components/Icon/Icon';
import RoundedInput from '@/components/RoundedInput';

interface ToggledSearchProps {
  buttonProps?: IconButtonProps;
  value: string;
  onChange: (value: string) => void;
}

const ToggledSearch: FC<ToggledSearchProps> = ({ buttonProps, value, onChange }) => {
  const { t } = useTranslation('common');
  const { palette } = useTheme();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    isSearchOpen && inputRef.current?.focus();
  }, [isSearchOpen]);

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          zIndex: 1,
          width: 200,
          height: '100%',
          transform: 'translateY(-50%)',
          backgroundColor: palette.background.default,
          borderRadius: '999px',
          maxWidth: isSearchOpen ? 300 : 0,
          opacity: isSearchOpen ? 1 : 0.5,
          overflow: 'hidden',
          transition: 'max-width .25s ease-in-out, opacity .25s ease-in-out',
        }}
      >
        <RoundedInput
          sx={{
            height: '100%',
            px: 0.5,
            py: 0,
            '.MuiInputBase-root': { height: '100%', p: 0, backgroundColor: palette.background.default },
            '.MuiInputBase-input': { p: 0, fontSize: 'body3.fontSize' },
          }}
          inputRef={inputRef}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ color: palette.grey[700] }} position="start">
                <Icon name="search" fontSize="small" sx={{ color: palette.darkPurple.dark }} />
              </InputAdornment>
            ),
          }}
          placeholder={t('searchPlaceholder')}
          value={value}
          onChange={event => onChange(event.target.value)}
          onBlur={() => setIsSearchOpen(false)}
        />
      </Box>

      <IconButton
        size="small"
        sx={{ '&:hover': { backgroundColor: palette.primary.main } }}
        {...buttonProps}
        onClick={() => setIsSearchOpen(true)}
      >
        <Icon name="search" fontSize="small" htmlColor={palette.primary.dark} />
      </IconButton>
    </Box>
  );
};

export default ToggledSearch;
