import { FC, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProjectsId } from '@/api/generated';
import Loader from '@/components/Loader/Loader';
import { ProjectContext } from '@/views/Project/contexts/ProjectContext';

interface ProjectProviderProps {
  children: ReactNode;
}

const ProjectProvider: FC<ProjectProviderProps> = ({ children }) => {
  const { projectSlug } = useParams();
  const { data: project } = useGetProjectsId(projectSlug!, {
    query: { enabled: !!projectSlug, staleTime: 60_000 },
  });

  if (!project) return <Loader id="use-project" />;

  return <ProjectContext.Provider value={{ project, projectSlug: projectSlug! }}>{children}</ProjectContext.Provider>;
};

export default ProjectProvider;
