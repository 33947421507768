import { Components, Theme } from '@mui/material/styles';

const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    popper: {
      zIndex: 2000,
    },
    tooltip: ({ theme }) => ({
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[800],
      fontSize: theme.typography.body2.fontSize,
      padding: theme.spacing(1),
    }),
    arrow: ({ theme }) => ({
      color: theme.palette.grey[300],
    }),
  },
};
export default MuiTooltip;
