import PSPDFKit, { Instance } from 'pspdfkit';
import { useEffect } from 'react';

type Params = {
  instance?: Instance;
  isFullScreen: boolean;
};

export const useFullscreenZoom = ({ instance, isFullScreen }: Params) => {
  useEffect(() => {
    if (!instance) return;
    instance.setViewState(viewState =>
      viewState.set('zoom', isFullScreen ? PSPDFKit.ZoomMode.FIT_TO_WIDTH : PSPDFKit.ZoomMode.AUTO),
    );
  }, [instance, isFullScreen]);
};
