import { FC, ReactNode } from 'react';
import { OrganizationContext } from '@/contexts/OrganizationContext';
import { useAuth } from '@/hooks/useAuth';

interface OrganizationProviderProps {
  children: ReactNode;
}

const OrganizationProvider: FC<OrganizationProviderProps> = ({ children }) => {
  const { currentUser } = useAuth();
  return (
    <OrganizationContext.Provider value={{ organization: currentUser.organization! }}>{children}</OrganizationContext.Provider>
  );
};

export default OrganizationProvider;
