import { useMatches } from 'react-router-dom';
import { ROUTER_IDS } from '@/services/linker';

const useRouteId = () => {
  const matches = useMatches();
  const { id: routeId } = matches[matches.length - 1];

  return routeId as ROUTER_IDS;
};

export default useRouteId;
