import { getGetProjectsIdQueryKey, getGetProjectsQueryKey, ProjectFull } from '@/api/generated';
import { QueryClient } from '@tanstack/react-query';

type Params = {
  queryClient: QueryClient;
  projectSlug?: string;
};

type ProjectsData = { projects: ProjectFull[] };
type ProjectsWithPagesData = { pages: ProjectsData[] };

const updateProjectsPages = (
  { queryClient, projectSlug }: Params,
  cb: (prevProject?: ProjectFull) => ProjectFull | undefined,
) => {
  queryClient.setQueriesData<ProjectsWithPagesData>({ queryKey: [getGetProjectsQueryKey()] }, prev => {
    if (!prev) return;

    const indexes = prev.pages.reduce<[number, number][]>((acc, page, pageIndex) => {
      const projectIndex = page.projects.findIndex(project => project.slug === projectSlug);
      return projectIndex !== -1 ? [...acc, [pageIndex, projectIndex]] : acc;
    }, []);

    if (indexes.length === 0) {
      return prev;
    }

    const updatedProjectsPages = [...prev.pages];
    indexes.forEach(([pageIndex, projectIndex]) => {
      updatedProjectsPages[pageIndex].projects = [...updatedProjectsPages[pageIndex].projects];
      const prevProject = updatedProjectsPages[pageIndex].projects[projectIndex];
      updatedProjectsPages[pageIndex].projects[projectIndex] = {
        ...prevProject,
        ...cb(prevProject),
      };
    });

    return { ...prev, pages: updatedProjectsPages };
  });
};

export const updateProjectCache = (
  { queryClient, projectSlug }: Params,
  cb: (prevProject?: ProjectFull) => ProjectFull | undefined,
) => {
  if (!projectSlug) return;
  queryClient.setQueryData<ProjectFull>(getGetProjectsIdQueryKey(projectSlug), prevProject => {
    if (prevProject) {
      const updatedProject = cb(prevProject)!;
      updateProjectsPages({ queryClient, projectSlug }, () => updatedProject);
      return updatedProject;
    }

    updateProjectsPages({ queryClient, projectSlug }, cb);
  });
};
