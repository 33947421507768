import { Mention, MentionsInput, MentionsInputProps } from 'react-mentions';
import { forwardRef, ReactElement, ReactNode, useMemo, useRef } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import Icon, { IconNames } from '@/components/Icon/Icon';
import { MentionType } from '@/api/generated';
import { getMentionTypeOptions, SuggestionDataItem } from '@/utils/getMentionTypeOptions';

interface RoundedMentionInput extends Omit<MentionsInputProps, 'children' | 'onSubmit'> {
  documents?: SuggestionDataItem[];
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  tools?: SuggestionDataItem[];
  mentionType?: MentionType;
}

interface RenderSuggestionsProps {
  suggestion: Omit<SuggestionDataItem, 'description' | 'type'>;
  highlightedDisplay: ReactNode;
  index: number;
  data: SuggestionDataItem[];
}

type IconType = 'wrench' | 'pdf';

const IconByType: Record<string, IconType> = {
  tool: 'wrench',
  document: 'pdf',
  org_document: 'pdf',
};

const MAX_HEIGHT = 300;
const MIN_MAX_HEIGHT = 240;

const RoundedMentionInput = forwardRef<HTMLTextAreaElement | null, RoundedMentionInput>(
  ({ value, documents = [], startIcon, endIcon, tools = [], ...props }, ref) => {
    const { palette } = useTheme();
    const refContainer = useRef<HTMLDivElement | null>(null);
    const inputRect = refContainer?.current && refContainer?.current.getBoundingClientRect();
    const suggestionHeight = useMemo(
      () => ((inputRect?.top || 0) >= window.outerHeight - MAX_HEIGHT ? MAX_HEIGHT : (inputRect?.top || 0) - MIN_MAX_HEIGHT),
      [inputRect?.top],
    );
    const mentionTypeOptions = getMentionTypeOptions(documents, tools);

    const renderSuggestions = (args: RenderSuggestionsProps): JSX.Element => {
      const { suggestion, highlightedDisplay, index, data } = args;
      const title = data[index].description || suggestion.display;
      const itemType = data[index].type as keyof typeof IconByType;

      return (
        <Stack key={index} flexDirection="row" alignItems="center" gap={1.5} title={title}>
          <Icon name={IconByType[itemType] as IconNames} fontSize="small" />

          <Typography variant="body2">{highlightedDisplay}</Typography>
        </Stack>
      );
    };

    return (
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: 5,
          boxShadow: 1,
          border: `1px solid ${palette.darkPurple.main}`,
          backgroundColor: palette.background.default,
          position: 'relative',
          lineHeight: 'body2.fontSize',
        }}
        ref={refContainer}
      >
        {startIcon && startIcon}
        <MentionsInput
          {...props}
          value={value}
          forceSuggestionsAboveCursor
          inputRef={ref}
          className="mentions"
          style={{
            suggestions: {
              list: {
                maxHeight: suggestionHeight,
                border: `1px solid ${palette.grey[200]}`,
                borderRadius: 8,
              },
            },
          }}
        >
          {mentionTypeOptions.map(({ data, trigger, displayTransform, markup }) => (
            <Mention
              renderSuggestion={(suggestion, _search, highlightedDisplay, index) =>
                renderSuggestions({
                  suggestion,
                  highlightedDisplay,
                  index,
                  data,
                })
              }
              data={data}
              trigger={trigger}
              markup={markup}
              displayTransform={displayTransform}
            />
          ))}
        </MentionsInput>
        {endIcon && endIcon}
      </Stack>
    );
  },
);

export default RoundedMentionInput;
