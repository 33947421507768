import { Components, Theme } from '@mui/material/styles';
import palette from '@/theme/palette';
import shadows from '@/theme/shadows';

const MuiSelect: Components['MuiSelect'] = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      backgroundColor: palette.primary.main,
      borderRadius: 24,
      color: palette.primary.dark,
      padding: theme.spacing(1.5, 4),
      cursor: 'pointer',
      '&.Mui-focused': { backgroundColor: palette.primary.dark, color: palette.primary.main },
      '&:before': {
        content: 'unset',
      },
      '&:after': {
        content: 'unset',
      },
      '& .MuiSelect-nativeInput': {
        display: 'none',
      },
      '& .MuiInputAdornment-root': {
        height: 'auto',
        maxHeight: 'unset',
        cursor: 'pointer',
        color: 'inherit',
        '&.MuiInputAdornment-positionStart': {
          marginRight: '0.25rem',
          '&.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)': { marginTop: 0 },
        },
        '&.MuiInputAdornment-positionEnd': {
          marginLeft: '0.25rem',
        },
      },
      '& .MuiInputBase-input.MuiSelect-select': {
        '&:focus': { backgroundColor: palette.primary.dark, color: palette.primary.main },
        padding: 0,
        '&.MuiFilledInput-input': {
          paddingRight: 0,
        },
      },
      '&:hover': {
        backgroundColor: palette.primary.dark,
        color: palette.background?.default,
        boxShadow: shadows[3],
      },
    }),
  },
} as never;
export default MuiSelect;
