import { FC, useEffect, useMemo, useRef } from 'react';
import { z } from 'zod';
import uniq from 'lodash/uniq';
import { Box, Button, FormControlLabel, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Dialog from '@/components/Dialog/Dialog';
import { DocumentMetadata } from '@/api/generated';
import { RangeList } from '@/utils/RangeList';
import CircleCheckbox from '@/components/Checkbox/CircleCheckbox';
import Slider from '@/components/Slider/Slider';

export interface AutoCountingSettingsDialogProps {
  isOpened: boolean;
  currentPage: number;
  document?: DocumentMetadata;
  onSave: (pages: number[], sensitivity: number) => void;
  onClose: () => void;
}

export const ValidationSchema = z.object({
  addCurrentPage: z.boolean(),
  addFavoritePages: z.boolean(),
  addCustomPages: z.boolean(),
  customRangesText: z.string().optional(),
  sensitivity: z.number(),
});

export type FormValue = z.infer<typeof ValidationSchema>;

const AutoCountingSettingsDialog: FC<AutoCountingSettingsDialogProps> = ({
  isOpened,
  currentPage,
  document,
  onSave,
  onClose,
}) => {
  const { t } = useTranslation('editor');
  const sliderMarks = [
    { value: 0, label: t('autoCountingSettingsDialog.sliderLow') },
    { value: 25 },
    { value: 50 },
    { value: 75 },
    { value: 100, label: t('autoCountingSettingsDialog.sliderHigh') },
  ];
  const customPagesInputRef = useRef<HTMLInputElement | null>(null);

  const { register, setValue, reset, watch, getValues, control, formState, setError, clearErrors, handleSubmit } =
    useForm<FormValue>({
      mode: 'onSubmit',
      resolver: zodResolver(ValidationSchema),
      defaultValues: {
        addCurrentPage: true,
        addFavoritePages: false,
        addCustomPages: false,
        customRangesText: '',
        sensitivity: 50,
      },
    });

  const addCustom = watch('addCustomPages');
  const addCurrent = watch('addCurrentPage');
  const addFavorite = watch('addFavoritePages');
  const customText = watch('customRangesText');

  const favoriteRangeList = useMemo(
    () => (document?.favorite_pages?.length ? RangeList.fromNumbers(document.favorite_pages) : undefined),
    [document],
  );

  useEffect(() => {
    if (!isOpened) reset();
  }, [isOpened]);

  useEffect(() => {
    if (addCustom) customPagesInputRef.current?.focus();
  }, [addCustom]);

  useEffect(() => {
    clearErrors('customRangesText');
  }, [customText, addCurrent, addFavorite, addCustom]);

  const handleSave = () => {
    const { addCurrentPage, addFavoritePages, addCustomPages, customRangesText, sensitivity } = getValues();
    const resultPages = [];

    if (addCustomPages) {
      const rangeList = RangeList.fromText(customRangesText ?? '');
      if (!rangeList) {
        setError('customRangesText', { message: t('autoCountingSettingsDialog.incorrectPagesFormat') });
        return;
      }

      const pages = rangeList.valueOf();
      if (pages[pages.length - 1] >= document!.pageNames!.length) {
        setError('customRangesText', {
          message: t('autoCountingSettingsDialog.incorrectPagesNumber', { maxPage: document!.pageNames!.length }),
        });
        return;
      }

      resultPages.push(...pages);
    }

    if (addCurrentPage) {
      resultPages.push(currentPage);
    }

    if (addFavoritePages) {
      resultPages.push(...favoriteRangeList!.valueOf());
    }

    onSave(uniq(resultPages), sensitivity);
  };

  return (
    <Dialog open={isOpened} component="form" onSubmit={handleSubmit(handleSave)}>
      <Stack gap={3.5}>
        <Stack gap={1.5}>
          <Box sx={{ fontSize: 'body1.fontSize', fontWeight: 'bold' }}>{t('autoCountingSettingsDialog.pagesRadiosLabel')}</Box>

          <Stack gap={1} sx={{ pl: 4 }}>
            <Controller
              control={control}
              name="addCurrentPage"
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={<CircleCheckbox sx={{ mr: 2 }} checked={value} onChange={onChange} />}
                  label={
                    <Box sx={{ fontSize: 'body3.fontSize' }}>
                      {t('autoCountingSettingsDialog.currentPage', { currentPage: currentPage + 1 })}
                    </Box>
                  }
                />
              )}
            />

            {favoriteRangeList && (
              <Controller
                control={control}
                name="addFavoritePages"
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={<CircleCheckbox sx={{ mr: 2 }} checked={value} onChange={onChange} />}
                    label={
                      <Box sx={{ fontSize: 'body3.fontSize' }}>
                        {t('autoCountingSettingsDialog.myFavorites', { pages: favoriteRangeList?.toString() })}
                      </Box>
                    }
                  />
                )}
              />
            )}

            <Controller
              control={control}
              name="addCustomPages"
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={<CircleCheckbox sx={{ mr: 2 }} checked={value} onChange={onChange} />}
                  label={
                    <TextField
                      inputRef={customPagesInputRef}
                      placeholder="1-15,20"
                      autoComplete="off"
                      size="small"
                      {...register('customRangesText')}
                      inputProps={{ sx: { width: 220 } }}
                      error={!!formState.errors['customRangesText']}
                      helperText={formState.errors['customRangesText']?.message ?? ' '}
                      sx={{ '& .MuiFormHelperText-root': { position: 'absolute', top: '100%', whiteSpace: 'nowrap' } }}
                      onClick={() => {
                        setValue('addCustomPages', true);
                      }}
                    />
                  }
                />
              )}
            />
          </Stack>
        </Stack>

        <Stack gap={3}>
          <Stack gap={1.5}>
            <Box sx={{ fontSize: 'body2.fontSize', fontWeight: 500 }}>{t('autoCountingSettingsDialog.sensitivity')}</Box>
            <Stack sx={{ pl: 4 }}>
              <Controller
                control={control}
                name="sensitivity"
                render={({ field }) => <Slider {...field} marks={sliderMarks} step={12.5} min={0} max={100} />}
              />
            </Stack>
          </Stack>

          <Stack direction="row" sx={{ gap: 2, px: 5, justifyContent: 'space-between' }}>
            <Button color="secondary" variant="contained" sx={{ minWidth: 136 }} onClick={onClose}>
              {t('autoCountingSettingsDialog.cancel')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={!addCurrent && !addFavorite && !addCustom}
              sx={{ minWidth: 136 }}
            >
              {t('autoCountingSettingsDialog.submit')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default AutoCountingSettingsDialog;
