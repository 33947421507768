import { ElementTransformer } from '@lexical/markdown';
import { ExcalidrawNode, $isExcalidrawNode } from '@/containers/PagesEditor/nodes/ExcalidrawNode';
import { getDeepestParagraph } from '@/containers/PagesEditor/utils/getDeepestParagraph';

const REGEXP = /<excalidraw hidden>(.*?)<\/excalidraw>/;

export const EXCALIDRAW_TRANSFORMER: ElementTransformer = {
  dependencies: [ExcalidrawNode],
  export: node => {
    const topNode = getDeepestParagraph(node);
    const firstChild = topNode?.getFirstChild();
    if (!firstChild || !$isExcalidrawNode(firstChild)) return null;

    return `<excalidraw hidden>${JSON.stringify(firstChild.exportJSON())}</excalidraw>`;
  },
  regExp: REGEXP,
  replace: (parentNode, _1, match) => {
    try {
      const json = JSON.parse(match[1]);
      const node = ExcalidrawNode.importJSON(json);
      parentNode.append(node);
    } catch {
      /* empty */
    }
  },
  type: 'element',
};
