import { Button, Stack, Typography, useTheme } from '@mui/material';
import Icon from '@/components/Icon/Icon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TABS } from '@/views/Knowledge/components/consts';

interface NoThreadsProps {
  setTabValue: (newVale: TABS) => void;
}

const NoThreads: FC<NoThreadsProps> = ({ setTabValue }) => {
  const { palette, spacing } = useTheme();
  const { t } = useTranslation('common');

  return (
    <Stack height="100%" justifyContent="center" alignItems="center" gap={5}>
      <Stack gap={2} alignItems="center">
        <Typography sx={{ color: palette.text.primary }}>{t('knowledge.noThreads.text1')}</Typography>
        <Typography sx={{ color: palette.text.primary }}>{t('knowledge.noThreads.text2')}</Typography>
      </Stack>
      <Button
        sx={{
          borderRadius: '999px',
          p: spacing(1.25, 3.5),
          '& .MuiButton-startIcon': { fontSize: 'h2.fontSize', mr: 1, svg: { fontSize: 'h2.fontSize' } },
        }}
        variant="contained"
        startIcon={<Icon name="chat" />}
        onClick={() => setTabValue(TABS.CHAT)}
      >
        {t('knowledge.noThreads.buttonText')}
      </Button>
    </Stack>
  );
};

export default NoThreads;
