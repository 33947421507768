import { createPortal } from 'react-dom';
import { FC, ReactNode, useEffect, useRef } from 'react';
import nextTick from '@/services/nextTick';

interface HeaderMainPortalProps {
  children: ReactNode;
}

export const HeaderMainPortal: FC<HeaderMainPortalProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    nextTick(() => {
      containerRef.current = document.getElementById('header-main-slot') as HTMLDivElement;
    });
  }, []);

  if (!containerRef.current) return null;

  return createPortal(children, containerRef.current);
};
