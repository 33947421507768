import { Components } from '@mui/material/styles';
import palette from '../palette';

const MuiAppBar: Components['MuiAppBar'] = {
  styleOverrides: {
    root: {
      mb: 5,
      backgroundColor: palette.background?.paper,
      borderBottom: `1px solid ${palette.primary.main}`,
      boxShadow: 'none',
    },
  },
};
export default MuiAppBar;
