import PSPDFKit, { AnnotationBackendJSON, Instance, UnknownAnnotationJSON } from 'pspdfkit';
import { useEffect } from 'react';
import differenceBy from 'lodash/differenceBy';
import { usePrevious } from '@uidotdev/usehooks';
import { exportAnnotationsJson } from '@/containers/DocumentEditor/utils/exportAnnotationsJson';
import { filterUselessAnnotations } from '@/utils/filterUselessAnnotations';

type Params = {
  instance?: Instance;
  annotations: AnnotationBackendJSON[];
  isEnabled: boolean;
};

export const useAnnotationsDiff = ({ instance, annotations, isEnabled }: Params) => {
  const previousAnnotations = usePrevious(annotations);

  useEffect(() => {
    if (!instance || !isEnabled || !previousAnnotations) return;

    (async () => {
      // TODO This should be moved exactly to the place where we need to compare annotations.
      const serializedAnnotations = await exportAnnotationsJson(instance);
      const existingAnnotations = filterUselessAnnotations(serializedAnnotations || previousAnnotations);

      const removedAnnotations = differenceBy(existingAnnotations, annotations, 'id') as UnknownAnnotationJSON[];
      const addedAnnotations = differenceBy(annotations, existingAnnotations, 'id') as UnknownAnnotationJSON[];

      const annotationsToRemove = removedAnnotations.map(annotation =>
        PSPDFKit.Annotations.fromSerializableObject({
          ...annotation,
          customData: { ...annotation.customData, skipDelete: true },
        }).set('id', annotation.id),
      );
      const annotationsToAdd = addedAnnotations.map(annotation =>
        PSPDFKit.Annotations.fromSerializableObject({
          ...annotation,
          customData: { ...annotation.customData, skipSave: true },
        }).set('id', annotation.id),
      );

      instance.delete(annotationsToRemove);
      instance.create(annotationsToAdd);
    })();
  }, [previousAnnotations, annotations, instance, isEnabled]);
};
