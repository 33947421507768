import { BaseSyntheticEvent, forwardRef, KeyboardEvent, useImperativeHandle, useRef } from 'react';
import { Box, type InputProps, SxProps } from '@mui/material';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { Key } from 'ts-key-enum';
import RoundedInput from '@/components/RoundedInput';

interface FormInputSubmitProps {
  error?: FieldError;
  register: UseFormRegisterReturn;
  handleSubmit?: (e?: BaseSyntheticEvent<object, unknown, unknown> | undefined) => Promise<void>;
  placeholder?: string;
  InputProps?: InputProps;
  sx?: SxProps;
  disabled?: boolean;
  onChange?: (text: string) => void;
}

export interface FormInputSubmitRef {
  focus: () => void;
}

const FormInputSubmit = forwardRef<FormInputSubmitRef, FormInputSubmitProps>(
  ({ disabled, error, handleSubmit, register, placeholder = '', InputProps, sx }, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
    }));

    const onSubmit = (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key !== Key.Enter || event.shiftKey) return;
      event.preventDefault();
      handleSubmit && handleSubmit();
    };

    return (
      <Box component="form" onSubmit={handleSubmit} sx={{ zIndex: 1000, position: 'relative' }}>
        <RoundedInput
          sx={sx}
          placeholder={placeholder}
          multiline
          error={!!error}
          disabled={disabled}
          InputProps={InputProps}
          inputProps={{ sx: { fontSize: 'body2.fontSize' } }}
          {...register}
          inputRef={inputRef}
          onKeyDown={onSubmit}
        />
      </Box>
    );
  },
);

export default FormInputSubmit;
