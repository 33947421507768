import { createTheme } from '@mui/material';
import components from './components';
import palette from './palette';
import typography from './typography';
import mixins from './mixins';
import shadows from './shadows';

// TODO Make types from the created theme, remove duplication inside palette itself.

declare module '@mui/material' {
  interface ButtonPropsColorOverrides {
    accent: true;
  }

  interface SvgIconPropsSizeOverrides {
    xsmall: true;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    selection: string;
  }

  interface Palette {
    accent: Palette['primary'];
    darkPurple: Palette['primary'];
    darkerPurple: Palette['primary'];
    low: Palette['primary'];
    mid: Palette['primary'];
    high: Palette['primary'];
    chat: Palette['primary'];
    controls: { text: string; background: string; border: string };
    likes: Palette['primary'];
    feedback: Palette['primary'];
    feedbackLike: Palette['primary'];
    pink: Palette['primary'];
    tags: string[];
  }

  interface PaletteOptions {
    accent: PaletteOptions['primary'];
    darkPurple: PaletteOptions['primary'];
    darkerPurple: PaletteOptions['primary'];
    low: PaletteOptions['primary'];
    mid: PaletteOptions['primary'];
    high: PaletteOptions['primary'];
    chat: PaletteOptions['primary'];
    controls: { text: string; background: string; border: string };
    likes: PaletteOptions['primary'];
    feedback: PaletteOptions['primary'];
    feedbackLike: PaletteOptions['primary'];
    pink: PaletteOptions['primary'];
    tags: string[];
  }
}
declare module '@mui/material/styles/createTypography' {
  interface Typography {
    base: Typography;
    body3: Typography;
    body4: Typography;
    icons: Typography;
  }

  interface TypographyOptions {
    base: TypographyOptions['body1'];
    body3: TypographyOptions['body1'];
    body4: TypographyOptions['body1'];
    icons: TypographyOptions['h3'];
  }
}

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    muiSwitchTrack: CSSProperties;
  }
  interface MixinsOptions {
    muiSwitchTrack?: CSSProperties;
  }
}

export default createTheme({
  components,
  typography,
  palette,
  shadows,
  shape: {
    borderRadius: 4,
  },
  mixins,
});
