import { z } from 'zod';

export const stakeholder = z.object({
  name: z.string(),
  role: z.string().optional(),
  company: z.string().optional(),
  email: z.string().email({ message: 'validation.stakeholders.email' }).optional().or(z.literal('')),
  phone: z.string().optional(),
});

const projectLocation = z.object({
  city: z.string().optional(),
  country: z.string().optional(),
  lat: z.number().nullable().optional(),
  lng: z.number().nullable().optional(),
  number: z.number().nullable().optional(),
  state: z.string().optional(),
  street: z.string().optional(),
  zip: z.string().nullable().optional(),
});

export const validationSchema = z.object({
  slug: z.string().optional(),
  name: z.string().min(1, { message: 'validation.name' }),
  address: z.string().optional(),
  location: projectLocation.optional(),
  type: z.string().optional(),
  delivery_method: z.string().optional(),
  project_size: z.string().optional(),
  owner: z.string().optional(),
  stakeholders: z.array(stakeholder).optional(),
});
