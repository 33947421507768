import { Components, Theme } from '@mui/material/styles';

const MuiPaper: Components<Theme>['MuiPaper'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius * 3,
      backgroundColor: theme.palette.background.default,
    }),
  },
};

export default MuiPaper;
