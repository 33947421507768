import { useSearchParams } from 'react-router-dom';
import { HomepageActions } from '@/views/Project/views/Header/types';
import { useEffect } from 'react';

export const useUrlAction = (actionName: HomepageActions, handler: () => void) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const action = searchParams.get('action');
    if (action !== actionName) return;

    handler();
    searchParams.delete('action');
    setSearchParams(searchParams);
  }, [actionName, searchParams]);
};
