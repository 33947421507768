import PSPDFKit from 'pspdfkit';
import { Box } from '@mui/material';
import Text from '@/components/Icon/svgs/text.svg?react';
import Note from '@/assets/note.svg?react';
import Search from '@/assets/search-small.svg?react';
import Callout from '@/assets/callout.svg?react';
import Stamp from '@/assets/stamp.svg?react';
import Link from '@/assets/link.svg?react';
import Print from '@/assets/print.svg?react';
import FitPage from '@/assets/fit-page.svg?react';
import Polyline from '@/assets/polyline.svg?react';
import Rectangle from '@/assets/rect.svg?react';
import Ellipse from '@/assets/ellipse.svg?react';
import ArrowIcon from '@/assets/arrow-icon.svg?react';
import Cloud from '@/assets/cloud.svg?react';
import RectangleArea from '@/assets/rectangle-area.svg?react';
import Crop from '@/assets/crop.svg?react';
import EllipseArea from '@/assets/ellipse-area.svg?react';
import FreeformHighlight from '@/assets/freeform-highlight.svg?react';
import TextHighlighter from '@/assets/text-highlighter.svg?react';
import Eraser from '@/assets/eraser.svg?react';
import DownloadElement from '@/assets/download.svg?react';
import MagicElement from '@/components/Icon/svgs/magic-wand.svg?react';
import Cards from '@/assets/cards.svg?react';
import SingleMode from '@/assets/single-mode.svg?react';
import DoubleMode from '@/assets/double-mode.svg?react';
import Continuous from '@/assets/continuous.svg?react';
import PerSpread from '@/assets/per_spread.svg?react';
import RotateLeft from '@/assets/rotate_left.svg?react';
import RotateRight from '@/assets/rotate_right.svg?react';
import Draw from '@/assets/drawing.svg?react';
import CountingElement from '@/assets/counting.svg?react';
import { injectToolbarService } from '@/containers/DocumentEditor/utils/ToolbarService';
import Icon from '@/components/Icon/Icon';
import { EquipmentDescriptionSearch, UnitType } from '@/api/generated';
import { MeasurementParams, PresetDataParam } from '@/containers/DocumentEditor/types';

type MultiCountParams = { openMultiCountTool: (category?: EquipmentDescriptionSearch | null) => void } & {
  category?: EquipmentDescriptionSearch | null;
  color?: string;
};

export const toolbarItems = {
  text: {
    translationKey: 'text',
    keyShortcut: 'T',
    icon: <Text />,
    action: injectToolbarService(toolbarService => {
      toolbarService.setPreset('text');
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.TEXT, true);
    }),
  },
  note: {
    translationKey: 'note',
    keyShortcut: 'N',
    icon: <Note />,
    action: injectToolbarService(toolbarService => {
      toolbarService.setPreset('note');
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.NOTE, true);
    }),
  },
  callout: {
    translationKey: 'callout',
    keyShortcut: 'Q',
    icon: <Callout />,
    action: injectToolbarService(toolbarService => {
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.CALLOUT, true);
    }),
  },
  markup: {
    translationKey: 'markup',
    icon: <Icon name="line" />,
    action: injectToolbarService(toolbarService => {
      toolbarService.setPreset('line');
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.SHAPE_LINE, true);
    }),
  },
  arrow: {
    translationKey: 'arrow',
    icon: <ArrowIcon />,
    action: injectToolbarService(toolbarService => {
      toolbarService.setPreset('arrow');
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.SHAPE_LINE, true);
    }),
  },
  rectangle: {
    translationKey: 'rectangle',
    icon: <Rectangle />,
    action: injectToolbarService<PresetDataParam>((toolbarService, { presetData }) => {
      presetData ? toolbarService.setDynamicPreset(presetData) : toolbarService.setPreset('rectangle');
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.SHAPE_RECTANGLE, true);
    }),
  },
  ellipse: {
    translationKey: 'ellipse',
    icon: <Ellipse />,
    action: injectToolbarService<PresetDataParam>((toolbarService, { presetData }) => {
      presetData ? toolbarService.setDynamicPreset(presetData) : toolbarService.setPreset('ellipse');
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.SHAPE_ELLIPSE, true);
    }),
  },
  polygon: {
    translationKey: 'polygon',
    icon: <Icon name="polygon" />,
    action: injectToolbarService(toolbarService => {
      toolbarService.setPreset('polygon');
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.SHAPE_POLYGON, true);
    }),
  },
  cloud: {
    translationKey: 'cloud',
    icon: <Cloud />,
    keyShortcut: 'K',
    action: injectToolbarService(toolbarService => {
      toolbarService.setPreset('cloudy-polygon');
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.SHAPE_POLYGON, true);
    }),
  },
  polyline: {
    translationKey: 'polyline',
    icon: <Polyline />,
    action: injectToolbarService(toolbarService => {
      toolbarService.setPreset('polyline');
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.SHAPE_POLYLINE, true);
    }),
  },
  draw: {
    translationKey: 'draw',
    icon: <Draw />,
    action: injectToolbarService(toolbarService => {
      toolbarService.setPreset('ink');
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.INK, true);
    }),
  },
  freeformHighlight: {
    translationKey: 'freeformHighlight',
    icon: <FreeformHighlight />,
    action: injectToolbarService(toolbarService => {
      toolbarService.setPreset('highlighter');
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.INK, true);
    }),
  },
  textHighlighter: {
    translationKey: 'textHighlighter',
    icon: <TextHighlighter />,
    keyShortcut: 'H',
    action: injectToolbarService(toolbarService => {
      toolbarService.setPreset('text-highlighter');
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.TEXT_HIGHLIGHTER, true);
    }),
  },
  eraser: {
    translationKey: 'eraser',
    icon: <Eraser />,
    action: injectToolbarService(toolbarService => {
      toolbarService.setPreset('ink');
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.INK_ERASER, true);
    }),
  },
  stamp: {
    translationKey: 'stamp',
    icon: <Stamp />,
    keyShortcut: 'S',
    action: injectToolbarService(toolbarService => {
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.STAMP_PICKER, true);
    }),
  },
  link: {
    translationKey: 'link',
    icon: <Link />,
    keyShortcut: 'H',
    shiftKey: true,
    action: injectToolbarService(toolbarService => {
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.LINK, true);
    }),
  },
  autoCount: {
    translationKey: 'autoCount',
    keyShortcut: 'W',
    icon: <MagicElement />,
    action: injectToolbarService<{ openAutoCountTool: () => void }>((_, { openAutoCountTool }) => {
      openAutoCountTool();
    }),
  },
  multiCount: {
    translationKey: 'multiCount',
    icon: <CountingElement />,
    keyShortcut: 'C',
    action: injectToolbarService<MultiCountParams>((toolbarService, { openMultiCountTool, category, color }) => {
      color && toolbarService.setMultiCountColor(color);
      openMultiCountTool(category);
    }),
  },
  polylength: {
    translationKey: 'polylength',
    icon: <Polyline />,
    keyShortcut: 'P',
    action: injectToolbarService<MeasurementParams>((toolbarService, { category, presetData }) => {
      toolbarService.openMeasurementTool('PERIMETER', {
        category,
        presetData,
        basePreset: 'perimeter',
        toolType: UnitType.linear,
      });
    }),
  },
  line: {
    translationKey: 'line',
    icon: <Icon name="line" />,
    keyShortcut: 'L',
    action: injectToolbarService<MeasurementParams>((toolbarService, { presetData, category }) => {
      toolbarService.openMeasurementTool('DISTANCE', { category, presetData, basePreset: 'distance', toolType: UnitType.linear });
    }),
  },
  rectangleArea: {
    translationKey: 'rectangleArea',
    icon: <RectangleArea />,
    keyShortcut: 'A',
    action: injectToolbarService<MeasurementParams>((toolbarService, { presetData, category }) => {
      toolbarService.openMeasurementTool('RECTANGLE_AREA', {
        category,
        presetData,
        basePreset: 'rectangle-area',
        toolType: UnitType.area,
      });
    }),
  },
  polygonArea: {
    translationKey: 'polygonArea',
    icon: <Icon name="polygon" />,
    keyShortcut: 'G',
    action: injectToolbarService<MeasurementParams>((toolbarService, { presetData, category }) => {
      toolbarService.openMeasurementTool('POLYGON_AREA', {
        category,
        presetData,
        basePreset: 'polygon-area',
        toolType: UnitType.area,
      });
    }),
  },
  ellipseArea: {
    translationKey: 'ellipseArea',
    icon: <EllipseArea />,
    keyShortcut: 'D',
    action: injectToolbarService<MeasurementParams>((toolbarService, { presetData, category }) => {
      toolbarService.openMeasurementTool('ELLIPSE_AREA', {
        category,
        presetData,
        basePreset: 'ellipse-area',
        toolType: UnitType.area,
      });
    }),
  },
  search: {
    translationKey: 'search',
    icon: <Search />,
    keyShortcut: 'F',
    ctrlKey: true,
    action: injectToolbarService(toolbarService => {
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.SEARCH, true);
    }),
  },
  rotateLeft: {
    icon: <RotateLeft />,
    action: injectToolbarService(toolbarService => {
      toolbarService.rotateLeft();
    }),
  },
  rotateRight: {
    icon: <RotateRight />,
    action: injectToolbarService(toolbarService => {
      toolbarService.rotateRight();
    }),
  },
  continuous: {
    icon: <Continuous />,
    action: injectToolbarService(toolbarService => {
      toolbarService.setScrollMode(PSPDFKit.ScrollMode.CONTINUOUS);
    }),
  },
  perSpread: {
    icon: <PerSpread />,
    action: injectToolbarService(toolbarService => {
      toolbarService.setScrollMode(PSPDFKit.ScrollMode.PER_SPREAD);
    }),
  },
  single: {
    icon: <SingleMode />,
    action: injectToolbarService(toolbarService => {
      toolbarService.setLayoutMode(PSPDFKit.LayoutMode.SINGLE);
    }),
  },
  double: {
    icon: <DoubleMode />,
    action: injectToolbarService(toolbarService => {
      toolbarService.setLayoutMode(PSPDFKit.LayoutMode.DOUBLE);
    }),
  },
  fitPage: {
    translationKey: 'fitPage',
    icon: <FitPage />,
    action: injectToolbarService(toolbarService => {
      toolbarService.fitPagetoViewport();
    }),
  },
  organizePages: {
    translationKey: 'organizePages',
    icon: <Cards />,
    action: injectToolbarService(toolbarService => {
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.DOCUMENT_EDITOR, true);
    }),
  },
  documentCrop: {
    translationKey: 'documentCrop',
    icon: <Crop />,
    action: injectToolbarService(toolbarService => {
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.DOCUMENT_CROP, true);
    }),
  },
  download: {
    translationKey: 'download',
    icon: (
      <Box sx={{ svg: { width: 16, height: 16 } }}>
        <DownloadElement />
      </Box>
    ),
    action: injectToolbarService<{ documentFilename: string }>((toolbarService, { documentFilename }) => {
      toolbarService.downloadFile(documentFilename);
    }),
  },
  print: {
    translationKey: 'print',
    icon: <Print />,
    keyShortcut: 'P',
    ctrlKey: true,
    action: injectToolbarService(toolbarService => {
      toolbarService.print();
    }),
  },
  pan: {
    translationKey: 'pan',
    keyShortcut: 'V',
    action: injectToolbarService(toolbarService => {
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.PAN);
    }),
  },
  multiAnnotationSelection: {
    translationKey: 'multiAnnotationSelection',
    keyShortcut: 'O',
    action: injectToolbarService(toolbarService => {
      toolbarService.toggleInteractionMode(PSPDFKit.InteractionMode.MULTI_ANNOTATIONS_SELECTION);
    }),
  },
  zoomIn: {
    translationKey: 'zoomIn',
    action: injectToolbarService(toolbarService => {
      toolbarService.zoomIn();
    }),
  },
  zoomOut: {
    translationKey: 'zoomOut',
    action: injectToolbarService(toolbarService => {
      toolbarService.zoomOut();
    }),
  },
  pageTransition: {
    translationKey: 'pageTransition',
    action: injectToolbarService(toolbarService => {
      toolbarService.toggleScrollMode();
    }),
  },
};

export type ToolbarItems = typeof toolbarItems;
type ByKeyObject = {
  itemKey: keyof ToolbarItems;
  shiftKey: boolean;
};

export const toolbarItemsByKey = Object.entries(toolbarItems)
  .filter(([, item]) => 'keyShortcut' in item)
  .reduce<Record<string, ByKeyObject[]>>((acc, [itemKey, item]) => {
    if (!('keyShortcut' in item)) return acc;

    acc[item.keyShortcut] = [
      ...(acc[item.keyShortcut] ?? []),
      {
        itemKey: itemKey as keyof ToolbarItems,
        shiftKey: 'shiftKey' in item && item.shiftKey,
      },
    ];

    return acc;
  }, {});
