import { createContext, Dispatch, SetStateAction } from 'react';

type LayoutStateContextValue = {
  isOpenHeader: boolean;
  toggleHeader: () => void;
  isFullScreen: boolean;
  sidePanelSize: number;
  setSidePanelSize: (size: number) => void;
  toggleFullScreen: Dispatch<SetStateAction<boolean>>;
};

export const LayoutStateContext = createContext<LayoutStateContextValue | null>(null);
