import { Components, Theme } from '@mui/material/styles';

const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
  styleOverrides: {
    root: {
      padding: 0,
    },
    inputRoot: {
      padding: 0,
    },
    input: ({ theme }) => ({
      '&.MuiOutlinedInput-input': {
        padding: theme.spacing(1.5),
      },
    }),
  },
};
export default MuiAutocomplete;
