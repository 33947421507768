import { $convertFromMarkdownString, $convertToMarkdownString, MultilineElementTransformer } from '@lexical/markdown';
import { LexicalNode } from 'lexical';
import {
  CollapsibleContainerNode,
  $isCollapsibleContainerNode,
  $createCollapsibleContainerNode,
} from '@lexical/playground/plugins/CollapsiblePlugin/CollapsibleContainerNode';
import {
  CollapsibleTitleNode,
  $isCollapsibleTitleNode,
  $createCollapsibleTitleNode,
} from '@lexical/playground/plugins/CollapsiblePlugin/CollapsibleTitleNode';
import {
  CollapsibleContentNode,
  $isCollapsibleContentNode,
  $createCollapsibleContentNode,
} from '@lexical/playground/plugins/CollapsiblePlugin/CollapsibleContentNode';
import { PAGES_TRANSFORMERS } from '@/containers/PagesEditor/transformers/index';

const CONTAINER_REGEXP_START = /<details>/;
const CONTAINER_REGEXP_END = /<\/details>/;
const TITLE_REGEXP_START = /<summary>/;
const TITLE_REGEXP_END = /<\/summary>/;

export const COLLAPSIBLE_TRANSFORMER: MultilineElementTransformer = {
  dependencies: [CollapsibleContainerNode, CollapsibleTitleNode, CollapsibleContentNode],
  export: (node: LexicalNode) => {
    if (!$isCollapsibleContainerNode(node)) return null;

    const content = node
      .getChildren()
      .map(child => {
        if ($isCollapsibleContentNode(child)) return $convertToMarkdownString(PAGES_TRANSFORMERS, child);
        if ($isCollapsibleTitleNode(child))
          return `<summary>\n${$convertToMarkdownString(PAGES_TRANSFORMERS, child)}\n</summary>\n`;
      })
      .filter(Boolean)
      .join('');

    return `<details>\n${content}\n</details>\n`;
  },
  regExpStart: CONTAINER_REGEXP_START,
  regExpEnd: CONTAINER_REGEXP_END,
  replace: (parentNode, _1, _2, _3, linesInBetween) => {
    if (!linesInBetween) return;
    const containerNode = $createCollapsibleContainerNode(false);

    const titleLines = [];
    const contentLines = [];
    let isTitle = false;
    for (const line of linesInBetween) {
      if (line.match(TITLE_REGEXP_START)) {
        isTitle = true;
        continue;
      }
      if (line.match(TITLE_REGEXP_END)) {
        isTitle = false;
        continue;
      }

      if (isTitle) {
        titleLines.push(line);
      } else {
        contentLines.push(line);
      }
    }

    const titleNode = $createCollapsibleTitleNode();
    $convertFromMarkdownString(titleLines.join('\n'), PAGES_TRANSFORMERS, titleNode);
    const contentNode = $createCollapsibleContentNode();
    $convertFromMarkdownString(contentLines.join('\n'), PAGES_TRANSFORMERS, contentNode);
    containerNode.append(titleNode, contentNode);
    parentNode.append(containerNode);
  },
  type: 'multiline-element',
};
