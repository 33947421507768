import { Components, Theme } from '@mui/material/styles';

const MuiCircularProgress: Components<Theme>['MuiCircularProgress'] = {
  styleOverrides: {
    colorPrimary: ({ theme }) => ({
      color: theme.palette.primary.dark,
    }),
    colorSecondary: ({ theme }) => ({
      color: theme.palette.secondary.dark,
    }),
  },
};
export default MuiCircularProgress;
