import { Components, Theme } from '@mui/material/styles';

const MuiTabs: Components<Theme>['MuiTabs'] = {
  styleOverrides: {
    root: {
      paddingBottom: 0,
      minHeight: 'unset',
    },
    flexContainer: {
      gap: 40,
    },
    indicator: ({ theme }) => ({
      backgroundColor: theme.palette.primary.dark,
    }),
  },
};
export default MuiTabs;
