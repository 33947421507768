import uniq from 'lodash/uniq';
import makeRange from 'lodash/range';

type Range = [start: number, end?: number];

export class RangeList {
  static fromNumbers(nums: number[]) {
    const ranges = nums.reduce<Range[]>((acc, num) => {
      const lastRange = acc[acc.length - 1];
      if (!lastRange) {
        return [[num]];
      }

      const rangeEnd = lastRange[1] || lastRange[0];
      if (rangeEnd + 1 === num) {
        lastRange[1] = num;
      } else {
        acc.push([num]);
      }

      return acc;
    }, []);

    return new RangeList(ranges);
  }

  static validate(text: string) {
    return text.match(/^([1-9][0-9]*(-[1-9][0-9]*)?,?)+$/);
  }

  /*
    Convert text in format 1-10,20 into range list [[0-9],[19]]
   */
  static fromText(text: string) {
    if (!RangeList.validate(text)) return null;

    const ranges = text
      .replace(/\s/, '')
      .split(',')
      .map(
        rangeStr =>
          rangeStr
            .split('-')
            .map(Number)
            .map(num => num - 1) as unknown as Range,
      )
      .filter(range => !!range.length);

    return new RangeList(ranges);
  }

  constructor(private ranges: Range[]) {}

  valueOf() {
    return uniq(
      this.ranges.flatMap(range => (range.length === 1 ? range[0] : makeRange(range[0], range[1] ? range[1] + 1 : undefined))),
    ).sort();
  }

  toString() {
    return this.ranges.flatMap(range => (range.length === 1 ? range[0] + 1 : range.map(num => num! + 1).join('-'))).join(',');
  }
}
