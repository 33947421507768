import { styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const RoundedInputLoadingButton = styled(LoadingButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '9px',
  marginRight: '2px',
  minWidth: 'auto',
  fontSize: theme.typography.h4.fontSize,
  lineHeight: 1,
  borderRadius: '50%',
  backgroundColor: theme.palette.grey[300],
}));

export default RoundedInputLoadingButton;
