import { FC } from 'react';
import { Box } from '@mui/material';
import Icon from '@/components/Icon/Icon';

interface CloudFileViewProps {
  type: 'folder' | 'file';
  name: string;
  onClick: () => void;
}

const CloudFileView: FC<CloudFileViewProps> = ({ type, name, onClick }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, cursor: 'pointer' }} onClick={onClick}>
    <Icon name={type} fontSize="small" />
    <Box>{name}</Box>
  </Box>
);

export default CloudFileView;
