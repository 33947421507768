import { ComponentProps, FC } from 'react';
import Icon from '@/components/Icon/Icon';
import RoundedInputLoadingButton from '@/components/RoundedInputLoadingButton';
import { useTheme } from '@mui/material';

type SendIconButtonProps = ComponentProps<typeof RoundedInputLoadingButton>;

const SendIconButton: FC<SendIconButtonProps> = ({ sx, ...props }) => {
  const { palette } = useTheme();
  return (
    <RoundedInputLoadingButton
      sx={{
        display: 'flex',
        color: palette.grey[700],
        backgroundColor: palette.grey[300],
        '&:hover': { color: palette.primary.dark },
        ...sx,
      }}
      size="small"
      variant="contained"
      type="submit"
      {...props}
    >
      <Icon name="send" fontSize="small" />
    </RoundedInputLoadingButton>
  );
};

export default SendIconButton;
