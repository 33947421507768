import { Mention, MentionType } from '@/api/generated';

export const getMentionsByType = (mentionsByType: Mention[], type: MentionType) =>
  mentionsByType
    .filter(({ mention_type }) => mention_type === type)
    .map(({ id, name, description, mention_type }) => ({
      id,
      display: name.split('_').join(' '),
      description: description ?? '',
      type: mention_type,
    }));
