import { $createTextNode, ParagraphNode, TextNode } from 'lexical';
import { backgroundColors, textColors } from '@/containers/PagesEditor/plugins/FloatingTextFormatToolbarPlugin/constants';
import { CustomTextMatchTransformer } from '@/containers/PagesEditor/types';

const STYLE_REGEXP = /((?:color)|(?:background-color)): (.+?);/;
const FORMAT_REGEXP = /<color style="((?:color)|(?:background-color)): (.+?);">(.*)<\/color>/;
const REGEXP = /<color.*/;

const textColorNameByColor = Object.fromEntries(Object.entries(textColors).map(([name, color]) => [color, name]));
const backgroundColorNameByColor = Object.fromEntries(Object.entries(backgroundColors).map(([name, color]) => [color, name]));

const replaceOneNode = (node: TextNode) => {
  const tagIndexes: number[] = [];
  const content = node.getTextContent();
  let lastIndex = 0;
  while (lastIndex !== -1) {
    const startIndex = content.indexOf('<color', lastIndex);
    if (startIndex === -1) break;

    const endIndex = content.indexOf('</color>', startIndex);
    if (endIndex === -1) break;

    lastIndex = endIndex + '</color>'.length;
    tagIndexes.push(startIndex, lastIndex);
  }
  if (!tagIndexes.length) return [node];
  tagIndexes.unshift(0);

  const substrings = tagIndexes
    .reduce<string[]>((acc, startTagIndex, index) => {
      const endTagIndex = tagIndexes[index + 1] ?? content.length;
      const substring = content.substring(startTagIndex, endTagIndex);
      substring && acc.push(substring);
      return acc;
    }, [])
    .filter(Boolean);

  const nextNodes = substrings.map(substring => {
    const textNode = $createTextNode();
    textNode.setFormat(node.getFormat());

    const styleMatch = substring.match(FORMAT_REGEXP);
    if (styleMatch) {
      const [, prop, color, text] = styleMatch;
      textNode.setStyle(`${prop}: ${color};`);
      textNode.setTextContent(text);
    } else {
      textNode.setTextContent(substring);
    }

    return textNode;
  });

  nextNodes.forEach(textNode => node.insertAfter(textNode));
  node.remove();

  return nextNodes;
};

export const COLOR_TRANSFORMER: CustomTextMatchTransformer = {
  dependencies: [TextNode, ParagraphNode],
  export: (node, textContent) => {
    const styleMatch = node.getStyle().match(STYLE_REGEXP);
    if (!styleMatch) return textContent;

    const [, prop, color] = styleMatch as [string, 'color' | 'background-color', string];
    const colorsMap = prop === 'color' ? textColorNameByColor : backgroundColorNameByColor;
    const colorName = colorsMap[color];
    if (!colorName) return textContent;

    return `<color style="${prop}: ${color};">${textContent}</color>`;
  },
  importRegExp: REGEXP,
  regExp: REGEXP,
  replace: nodes => nodes.flatMap(replaceOneNode),
  type: 'text-match',
};
