import { FC, ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog, { ConfirmDialogTexts } from '@/components/ConfirmDialog';
import { ConfirmDialogContext, ConfirmDialogContextValue } from '@/contexts/ConfirmDialogContext';

interface ConfirmDialogProviderProps {
  children: ReactNode;
}

export const ConfirmDialogProvider: FC<ConfirmDialogProviderProps> = ({ children }) => {
  const [isOpened, setIsOpened] = useState(false);
  const { t } = useTranslation('common');

  const defaultTexts = { confirm: t('confirmDialog.confirm'), cancel: t('confirmDialog.cancel') };
  const texts = useRef<ConfirmDialogTexts>({ ...defaultTexts, title: '' });
  const resolveRef = useRef<(result: boolean | undefined) => void>(() => false);

  const contextValue: ConfirmDialogContextValue = {
    showConfirmDialog: (nextTexts: ConfirmDialogTexts) => {
      texts.current = { ...defaultTexts, ...nextTexts };
      setIsOpened(true);

      return new Promise<boolean | undefined>(resolve => (resolveRef.current = resolve));
    },
  };

  const onDialogClose = (result?: boolean) => {
    resolveRef.current(result);
    setIsOpened(false);
  };

  return (
    <ConfirmDialogContext.Provider value={contextValue}>
      <ConfirmDialog isOpened={isOpened} texts={texts.current} onClose={onDialogClose} />
      {children}
    </ConfirmDialogContext.Provider>
  );
};
