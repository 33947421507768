import { FC, ReactNode, Suspense, useCallback, useState } from 'react';
import Lottie from 'lottie-react';
import { Box } from '@mui/material';
import Loader from '@/components/Loader/Loader';
import pellesLoader from '@/components/Loader/Pelles_lottie.json';
import { LoaderContext } from '@/contexts/LoaderContext';

interface LoaderProviderProps {
  children: ReactNode;
}

const LoaderProvider: FC<LoaderProviderProps> = ({ children }) => {
  const [loadingIds, setLoadingIds] = useState(new Set<string>());

  const addLoadingId = useCallback((id: string) => {
    setLoadingIds(ids => new Set(ids).add(id));
  }, []);

  const removeLoadingId = useCallback((id: string) => {
    setLoadingIds(ids => new Set([...ids].filter(loadingId => loadingId !== id)));
  }, []);

  const isLoading = !!loadingIds.size;

  return (
    <LoaderContext.Provider value={{ addLoadingId, removeLoadingId }}>
      <Suspense fallback={<Loader id="initial" />}>
        <Box
          data-testid="global-loader"
          component={Lottie}
          animationData={pellesLoader}
          loop={isLoading}
          disabled={!isLoading}
          sx={{
            position: 'absolute',
            top: '50%',
            right: '50%',
            left: '50%',
            height: '80px',
            width: '80px',
            zIndex: isLoading ? 2000 : -1,
            opacity: isLoading ? 1 : 0,
          }}
        />
        {children}
      </Suspense>
    </LoaderContext.Provider>
  );
};

export default LoaderProvider;
