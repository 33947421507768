import { ComponentProps, FC, ReactNode } from 'react';
import { SliderThumb as MuiSliderThumb } from '@mui/material';
import PellesLogo from '@/assets/pelles-logo.svg?react';

type SliderThumbProps = ComponentProps<typeof MuiSliderThumb> & { children: ReactNode };

const SliderThumb: FC<SliderThumbProps> = props => (
  <MuiSliderThumb {...props}>
    {props.children}
    <PellesLogo />
  </MuiSliderThumb>
);

export default SliderThumb;
