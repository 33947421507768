import { Instance, ViewState } from 'pspdfkit';
import isMeasurementMode from '@/containers/DocumentEditor/utils/isMeasurementMode';

type Params = {
  instance: Instance;
  viewState: ViewState;
  prevViewState: ViewState;
};
export const keepSelectingToolForMeasurements = ({ instance, viewState, prevViewState }: Params) => {
  if (prevViewState.interactionMode === viewState.interactionMode || !viewState.interactionMode) {
    return;
  }

  instance.setViewState(currentViewState =>
    currentViewState.set('keepSelectedTool', isMeasurementMode(currentViewState.interactionMode)),
  );
};
