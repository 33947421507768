import PSPDFKit, { AnnotationBackendJSON } from 'pspdfkit';
import { MutableRefObject } from 'react';
import { usePspdfkit } from '@/containers/DocumentEditor/internalHooks/usePspdfkit';
import { useGetProjectsIdDocumentsDocumentId } from '@/api/generated';
import { useProject } from '@/views/Project/hooks/useProject';
import withInstantJsonEnvelop from '@/containers/DocumentEditor/utils/withInstantJsonEnvelop';
import { isAnnotationsWithPoints } from '@/containers/DocumentEditor/guards/isAnnotationsWithPoints';
import { SingleDocumentSource } from '@/containers/DocumentEditor/types';

type Params = {
  isEnabled?: boolean;
  containerRef: MutableRefObject<HTMLDivElement | null>;
  source?: SingleDocumentSource;
  annotations?: AnnotationBackendJSON[];
};

const URL_STORE_TIME = 1000 * 60 * 20; // 20 minutes

export const useSinglePspdf = ({ isEnabled, containerRef, source, annotations = [] }: Params) => {
  const { projectSlug } = useProject();

  const { data: document, isLoading: isDocumentLoading } = useGetProjectsIdDocumentsDocumentId(
    projectSlug,
    { document_id: source?.documentId ?? 'fake' },
    { query: { enabled: isEnabled && !!source, staleTime: URL_STORE_TIME } },
  );

  const { instance, isLoading, loadingPercent, unload } = usePspdfkit({
    isEnabled: isEnabled && !!document,
    containerRef,
    url: document && (source?.page !== undefined && document.urls ? document.urls[source.page] : document?.url),
    instanceConfig: {
      initialViewState: PSPDFKit.viewStateFromOpenParameters(new PSPDFKit.ViewState({ readOnly: true, showToolbar: false })),
      instantJSON: withInstantJsonEnvelop(
        annotations.filter(annotation => !isAnnotationsWithPoints(annotation) || annotation.points.length > 1),
      ),
    },
  });

  return { instance, isLoading: isDocumentLoading || isLoading, loadingPercent, unload };
};
