import { Components, Theme } from '@mui/material/styles';

const MuiFormLabel: Components<Theme>['MuiFormLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      paddingBottom: theme.spacing(0.5),
    }),
  },
};
export default MuiFormLabel;
