import { $getRoot, createEditor, SerializedEditorState } from 'lexical';
import Nodes from '@/containers/PagesEditor/nodes/Nodes';
import { $createElementNodeFromMarkdown } from '@/containers/PagesEditor/utils/$createElementNodeFromMarkdown';

export const convertMarkdownToLexicalState = (
  markdown: string,
  { shouldPreserveNewLines = false }: { shouldPreserveNewLines?: boolean } = {},
) =>
  new Promise<SerializedEditorState>(resolve => {
    const editor = createEditor({ nodes: Nodes });
    // We can generate markdown node only from inside active editor.
    editor.update(
      () => {
        const root = $getRoot();
        const node = $createElementNodeFromMarkdown(markdown, { shouldPreserveNewLines });
        root.append(...node.getChildren());
      },
      { onUpdate: () => resolve(editor.getEditorState().toJSON()) },
    );
  });
