import { Checkbox, CheckboxProps, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import UnCheckedIcon from '@/assets/unchecked-ring.svg?react';
import Checked from '@/assets/checked-ring.svg?react';

interface CircleCheckboxProps extends Omit<CheckboxProps, 'size'> {
  size?: number;
}

const CircleCheckbox = forwardRef<HTMLButtonElement, CircleCheckboxProps>(({ size = 24, sx, ...props }, ref) => {
  const { palette } = useTheme();

  return (
    <Checkbox
      {...props}
      ref={ref}
      sx={{ p: 0, color: palette.grey[700], ...sx }}
      icon={<UnCheckedIcon style={{ height: size, width: size }} />}
      checkedIcon={<Checked style={{ height: size, width: size }} />}
    />
  );
});

export default CircleCheckbox;
