import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyContext } from '@/contexts/CurrencyContext';

interface CurrencyProviderProps {
  children: ReactNode;
}

const CurrencyProvider: FC<CurrencyProviderProps> = ({ children }) => {
  const { i18n } = useTranslation('project');

  const symbol = Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'USD',
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('');

  return <CurrencyContext.Provider value={{ symbol }}>{children}</CurrencyContext.Provider>;
};

export default CurrencyProvider;
