import { ProjectFull, User } from '@/api/generated';

type Params = {
  user: User;
  project: ProjectFull;
};

export const addUserToProjectCollaborationList = ({ user, project }: Params): ProjectFull => ({
  ...project,
  collaborate_with: [...(project?.collaborate_with || []), user],
});

export const removeUserFromProjectCollaborationList = ({ user: userToRemove, project }: Params): ProjectFull => ({
  ...project,
  collaborate_with: (project?.collaborate_with || []).filter(user => user._id !== userToRemove._id),
});
