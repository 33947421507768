import { Snackbar, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import { SharedProps, SnackbarKey, useSnackbar } from 'notistack';

interface ToastProps extends SharedProps {
  id: SnackbarKey;
  message: string;
}

const Toast = forwardRef<HTMLDivElement, ToastProps>((props, ref) => {
  const { id, autoHideDuration, anchorOrigin, className, TransitionProps, transitionDuration, message, variant } = props;
  const { closeSnackbar } = useSnackbar();
  const { palette } = useTheme();

  const variantsSx = {
    default: {
      borderColor: palette.darkerPurple.light,
      color: palette.primary.dark,
      backgroundColor: palette.primary.main,
    },
    error: {
      borderColor: palette.error.dark,
      color: palette.error.dark,
      backgroundColor: palette.background.default,
    },
  };

  const variantSx = variant && variant in variantsSx ? variantsSx[variant as never] : variantsSx.default;

  return (
    <Snackbar
      className={className}
      ref={ref}
      sx={{
        cursor: 'pointer',
        position: 'static',
        '& .MuiPaper-root': {
          border: `1px solid`,
          borderRadius: 3,
          boxShadow: 2,
          ...variantSx,
        },
      }}
      open
      onClick={() => closeSnackbar(id)}
      message={message}
      autoHideDuration={autoHideDuration}
      transitionDuration={transitionDuration}
      TransitionProps={TransitionProps}
      anchorOrigin={anchorOrigin}
    />
  );
});

export default Toast;
