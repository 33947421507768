import { Context, useContext } from 'react';

type Params<C> = {
  context: Context<C>;
  hookName: string;
  contextName: string;
};

export const useSafeContext = <C>({ context, hookName, contextName }: Params<C>) => {
  const contextValue = useContext(context);

  if (!contextValue) throw new Error(`${hookName} should be used under the ${contextName} provider`);

  return contextValue;
};
