import type { Klass, LexicalNode } from 'lexical';

import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { HashtagNode } from '@lexical/hashtag';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { MarkNode } from '@lexical/mark';
import { OverflowNode } from '@lexical/overflow';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';

import { CollapsibleContainerNode } from '@lexical/playground/plugins/CollapsiblePlugin/CollapsibleContainerNode';
import { CollapsibleContentNode } from '@lexical/playground/plugins/CollapsiblePlugin/CollapsibleContentNode';
import { CollapsibleTitleNode } from '@lexical/playground/plugins/CollapsiblePlugin/CollapsibleTitleNode';
import { EmojiNode } from '@lexical/playground/nodes/EmojiNode';
import { FigmaNode } from '@lexical/playground/nodes/FigmaNode';
import { KeywordNode } from '@lexical/playground/nodes/KeywordNode';
import { LayoutContainerNode } from '@lexical/playground/nodes/LayoutContainerNode';
import { LayoutItemNode } from '@lexical/playground/nodes/LayoutItemNode';
import { MentionNode } from '@lexical/playground/nodes/MentionNode';
import { PageBreakNode } from '@lexical/playground/nodes/PageBreakNode';
import { TweetNode } from '@lexical/playground/nodes/TweetNode';
import { YouTubeNode } from '@lexical/playground/nodes/YouTubeNode';
import { ExcalidrawNode } from '@/containers/PagesEditor/nodes/ExcalidrawNode';

const Nodes: Array<Klass<LexicalNode>> = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  HashtagNode,
  CodeHighlightNode,
  AutoLinkNode,
  LinkNode,
  OverflowNode,
  MentionNode,
  EmojiNode,
  ExcalidrawNode,
  KeywordNode,
  HorizontalRuleNode,
  TweetNode,
  YouTubeNode,
  FigmaNode,
  MarkNode,
  CollapsibleContainerNode,
  CollapsibleContentNode,
  CollapsibleTitleNode,
  PageBreakNode,
  LayoutContainerNode,
  LayoutItemNode,
];

export default Nodes;
