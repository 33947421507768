import { Components, Theme } from '@mui/material/styles';

const MuiPaginationItem: Components<Theme>['MuiPaginationItem'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: 24,
      height: 24,
      mx: 0.5,
      fontSize: theme.typography.body3.fontSize,
      '&.Mui-selected': {
        color: theme.palette.background.default,
        '&:hover': {
          color: theme.palette.text.primary,
        },
      },
    }),
    previousNext: ({ theme }) => ({
      px: 0,
      m: 0,
      border: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.darkPurple.main,
      },
    }),
  },
};
export default MuiPaginationItem;
