import palette from './palette';
import { CSSProperties, MixinsOptions } from '@mui/material/styles/createMixins';

interface MixinsProps extends MixinsOptions {
  muiSwitchTrack?: CSSProperties;
}

const mixins: MixinsProps = {
  muiSwitchTrack: {
    color: palette.background?.default,
    position: 'absolute',
    fontSize: '10px',
    pb: '1px',
    top: '50%',
    left: '50%',
    zIndex: 1000,
  },
};

export default mixins;
