import { forwardRef } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@/assets/upload.svg?react';
import { useProjectFormDialog } from '@/hooks/useProjectFormDialog';

const CreateProjectButton = forwardRef<HTMLButtonElement>((props, ref) => {
  const { t } = useTranslation('projects');
  const { showProjectFormDialog } = useProjectFormDialog();

  return (
    <Button
      ref={ref}
      variant="contained"
      color="accent"
      onClick={() => showProjectFormDialog()}
      sx={{ flexShrink: 0, px: 3.5, py: 1.5, lineHeight: 1.2, fontSize: 'h4.fontSize', borderRadius: 6 }}
      startIcon={<AddIcon />}
      {...props}
    >
      {t('header.buttons.upload')}
    </Button>
  );
});

export default CreateProjectButton;
