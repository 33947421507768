import { FC, KeyboardEvent, useCallback, useState } from 'react';
import { Box, Collapse, Stack, TextField, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';
import { Controller, useFormContext } from 'react-hook-form';
import TextInput from '@/components/TextInput/TextInput';
import GoogleAutoComplete from '@/components/GoogleAutoComplete/GoogleAutoComplete';
import SelectControl from '@/components/SelectControl';
import Stakeholders from '@/views/Projects/components/ProjectFormDialog/components/Stakeholders';
import { ProjectFormValue } from '@/views/Projects/components/ProjectFormDialog/types';
import Icon from '@/components/Icon/Icon';

interface ProjectFormProps {}

const fieldNames: Array<keyof ProjectFormValue> = ['name', 'address', 'type', 'delivery_method', 'project_size', 'owner'];
const fieldIds = fieldNames.map(name => `projectUpdate-${name}`);

const ProjectForm: FC<ProjectFormProps> = () => {
  const { t } = useTranslation('projectUpdate');
  const { palette } = useTheme();
  const { control, setValue, setFocus } = useFormContext<ProjectFormValue>();
  const [isOpened, setIsOpened] = useState(false);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code !== Key.Enter) return;
    event.preventDefault();

    const currentFieldIndex = fieldIds.findIndex(id => (event.target as HTMLInputElement).id === id);
    if (currentFieldIndex >= 0 && fieldIds[currentFieldIndex + 1]) {
      setFocus(fieldNames[currentFieldIndex + 1]);
    }
  };

  const onPlaceSelected = useCallback((place: google.maps.places.PlaceResult) => {
    const getComponentByType = (targetType: string) =>
      place.address_components?.find(({ types }) => types.find(type => type === targetType))?.long_name;

    setValue('location', {
      number: parseInt(getComponentByType('street_number') || '', 10) || null,
      street: getComponentByType('route'),
      state: getComponentByType('administrative_area_level_1'),
      city: getComponentByType('locality'),
      country: getComponentByType('country'),
      zip: getComponentByType('postal_code'),
      lat: place.geometry?.location?.lat(),
      lng: place.geometry?.location?.lng(),
    });
  }, []);

  return (
    <Stack gap={1.25}>
      <Controller
        control={control}
        render={({ field }) => (
          <TextField
            placeholder={t('details.namePlaceholder')}
            id={fieldIds[0]}
            required
            variant="standard"
            InputProps={{ disableUnderline: true, sx: { fontSize: 'h2.fontSize', fontWeight: 'bold' } }}
            inputProps={{ sx: { textAlign: 'center' } }}
            onKeyDown={handleKeyDown}
            autoComplete="off"
            {...field}
          />
        )}
        name={fieldNames[0]}
      />
      <GoogleAutoComplete
        label={t('details.address')}
        placeholder={t('details.addressPlaceholder')}
        id={fieldIds[1]}
        name={fieldNames[1]}
        control={control}
        labelSx={{ fontWeight: 'fontWeightMedium' }}
        options={{ types: ['address'] }}
        onPlaceSelected={onPlaceSelected}
        onKeyDown={handleKeyDown}
      />

      <Collapse in={isOpened}>
        <Stack gap={3} sx={{ mt: 2 }}>
          <Box sx={{ fontSize: 'h3.fontSize', fontWeight: 'bold' }}>{t('details.title')}</Box>
          <Box sx={{ display: 'grid', gap: 3, gridTemplateColumns: '1fr 1fr' }}>
            <TextInput
              label={t('details.generalContractor')}
              placeholder={t('details.generalContractorPlaceholder')}
              control={control}
              id={fieldIds[5]}
              name={fieldNames[5]}
              labelSx={{ fontWeight: 'fontWeightMedium' }}
              translate="projectUpdate"
              onKeyDown={handleKeyDown}
            />
            <SelectControl
              label={t('details.type')}
              labelSx={{ fontWeight: 'fontWeightMedium' }}
              options={t('projectTypes', { returnObjects: true })}
              placeholder={t('details.typePlaceholder')}
              control={control}
              id={fieldIds[2]}
              name={fieldNames[2]}
              freeSolo
              onKeyDown={handleKeyDown}
            />
          </Box>
          <Box sx={{ display: 'grid', gap: 3, gridTemplateColumns: '1fr 1fr' }}>
            <SelectControl
              label={t('details.deliveryMethod')}
              labelSx={{ fontWeight: 'fontWeightMedium' }}
              options={t('deliveryMethods', { returnObjects: true })}
              placeholder={t('details.deliveryMethodPlaceholder')}
              control={control}
              id={fieldIds[3]}
              name={fieldNames[3]}
              freeSolo
              onKeyDown={handleKeyDown}
            />
            <TextInput
              label={t('details.projectSize')}
              placeholder={t('details.projectSizePlaceholder')}
              control={control}
              id={fieldIds[4]}
              name={fieldNames[4]}
              labelSx={{ fontWeight: 'fontWeightMedium' }}
              translate="projectUpdate"
              onKeyDown={handleKeyDown}
            />
          </Box>
          <Stakeholders control={control} />
        </Stack>
      </Collapse>

      <Box
        sx={{
          display: 'flex',
          gap: 0.5,
          alignItems: 'center',
          fontSize: 'body3.fontSize',
          color: palette.primary.dark,
          cursor: 'pointer',
          '&:hover': { textDecoration: 'underline' },
        }}
        onClick={() => setIsOpened(prev => !prev)}
      >
        {isOpened ? t('details.showLess') : t('details.showMore')}
        <Icon name="arrowDown" fontSize="small" sx={{ transform: `rotate(${isOpened ? 180 : 0}deg)` }} />
      </Box>
    </Stack>
  );
};

export default ProjectForm;
