import { $createParagraphNode } from 'lexical';
import { $convertFromMarkdownString } from '@lexical/markdown';
import { PAGES_TRANSFORMERS } from '@/containers/PagesEditor/transformers';

export const $createElementNodeFromMarkdown = (
  text: string,
  { shouldPreserveNewLines = false }: { shouldPreserveNewLines?: boolean } = {},
) => {
  const newParagraphNode = $createParagraphNode();
  $convertFromMarkdownString(text, PAGES_TRANSFORMERS, newParagraphNode, shouldPreserveNewLines);
  return newParagraphNode;
};
