import React, { FC, useState } from 'react';
import { useStorageState } from 'react-storage-hooks';
import { LayoutStateContext } from '@/views/Project/contexts/LayoutStateContext';

interface LayoutStateProviderProps {
  children: React.ReactNode;
}

const LayoutStateProvider: FC<LayoutStateProviderProps> = ({ children }) => {
  const [isOpenHeader, setIsOpenHeader] = useStorageState(localStorage, 'projectHeader', true);
  const [isFullScreen, setIsFullScreen] = useStorageState(localStorage, 'projectFullScreen', false);
  const [sidePanelSize, setSidePanelSize] = useState(0);

  const toggleHeader = () => !isFullScreen && setIsOpenHeader(prevState => !prevState);

  return (
    <LayoutStateContext.Provider
      value={{
        isOpenHeader: isFullScreen ? !isFullScreen : isOpenHeader,
        toggleHeader,
        isFullScreen,
        sidePanelSize,
        setSidePanelSize,
        toggleFullScreen: setIsFullScreen,
      }}
    >
      {children}
    </LayoutStateContext.Provider>
  );
};

export default LayoutStateProvider;
