import { DocumentMetadata } from '@/api/generated';

export type Size = { width: number; height: number };

export enum STATUS {
  LOADING = 'loading',
  ERROR = 'error',
  COMPLETED = 'completed',
}

export interface FileToUpload {
  status: STATUS;
  file: DocumentMetadata | ExtendedFile;
  id: string;
  progress: number;
}

interface ExtendedFile extends File {
  filename: string;
}
