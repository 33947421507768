import { IconButton, IconButtonProps, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import LogoIcon from '@/assets/logo-icon.svg?react';
import Icon from '@/components/Icon/Icon';

interface KnowledgeBaseButtonProps extends IconButtonProps {
  isActive: boolean;
}

const KnowledgeBaseButton = forwardRef<HTMLButtonElement, KnowledgeBaseButtonProps>(({ isActive, ...props }, ref) => {
  const { palette } = useTheme();

  return (
    <IconButton
      ref={ref}
      color="primary"
      {...props}
      sx={{
        px: 1.75,
        py: 1.5,
        position: 'fixed',
        bottom: 24,
        right: 25,
        zIndex: 1100,
        backgroundColor: isActive ? palette.accent.light : palette.feedback.main,
        color: palette.feedback.dark,
        maxWidth: 64,
        maxHeight: 64,
        '&:hover': {
          backgroundColor: palette.secondary.dark,
          boxShadow: 1,
        },
        ...props.sx,
      }}
    >
      {isActive ? <Icon sx={{ fontSize: 'h1.fontSize' }} name="x" /> : <LogoIcon style={{ marginLeft: 2 }} />}
    </IconButton>
  );
});

export default KnowledgeBaseButton;
