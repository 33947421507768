import { $isParagraphNode, LexicalNode } from 'lexical';

export const getDeepestParagraph = (node: LexicalNode) => {
  if (!$isParagraphNode(node)) return null;

  let topNode = node;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const child = topNode.getFirstChild();
    if ($isParagraphNode(child)) {
      topNode = child;
    } else {
      return topNode;
    }
  }
  return topNode;
};
