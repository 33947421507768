import { Grid, GridProps } from '@mui/material';
import Typography, { TypographyTypeMap } from '@mui/material/Typography';
import { FC, ReactNode } from 'react';

interface CellProps extends GridProps {
  children: ReactNode;
  fontWeight?: TypographyTypeMap['props']['fontWeight'];
  fontSize?: TypographyTypeMap['props']['fontSize'];
  textAlign?: TypographyTypeMap['props']['textAlign'];
  allowEllipsis?: boolean;
}

const Cell: FC<CellProps> = ({
  fontWeight = 'fontWeightRegular',
  fontSize = 'h4.fontSize',
  textAlign = 'left',
  allowEllipsis = false,
  component = 'p',
  xs,
  sx,
  children,
  ...rest
}) => {
  const title = typeof children === 'string' ? children : undefined;

  return (
    <Grid item xs={xs} sx={sx}>
      <Typography
        title={title}
        component={component}
        fontWeight={fontWeight}
        fontSize={fontSize}
        textAlign={textAlign}
        {...rest}
        sx={{
          ...(allowEllipsis && {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }),
        }}
      >
        {children}
      </Typography>
    </Grid>
  );
};

export default Cell;
