import { Components, Theme } from '@mui/material/styles';

const MuiSwitch: Components<Theme>['MuiSwitch'] = {
  styleOverrides: {
    switchBase: ({ theme }) => ({
      '&.Mui-checked+.MuiSwitch-track': { backgroundColor: theme.palette.primary.dark },
      '&.Mui-checked .MuiSwitch-thumb': { color: theme.palette.primary.dark },
    }),
  },
};
export default MuiSwitch;
