import { FC } from 'react';
import { Box, CssBaseline, Stack } from '@mui/material';
import { Descope } from '@descope/react-sdk';

const Auth: FC = () => (
  <Stack sx={{ minHeight: '100vh', justifyContent: 'center', alignItems: 'center' }} data-testid="auth-form">
    <Box sx={{ maxWidth: 600 }}>
      <CssBaseline />
      <Descope flowId="sign-in" />
    </Box>
  </Stack>
);

export default Auth;
