import { Components, Theme } from '@mui/material/styles';

const MuiToolbar: Components['MuiToolbar'] = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      '&.MuiToolbar-regular': { padding: theme.spacing(1.25, 5) },
    }),
  },
} as never;
export default MuiToolbar;
