import { FC, useEffect, useState } from 'react';
import { Box, ButtonBase, CircularProgress, Stack } from '@mui/material';
import CloudFileView from '@/views/Projects/components/ProjectFormDialog/components/CloudFileView';
import nextTick from '@/services/nextTick';
import Dialog from '@/components/Dialog/Dialog';
import { useGetMicrosoftFiles, useListFiles } from '@/api/generated';
import { DriveFile, DriveFileType, OneDriveFile } from '@/types';
import { Descope } from '@descope/react-sdk';
import { isSocialLoginError } from '@/views/Projects/components/ProjectFormDialog/utils/isSocialLoginError';
import { HomepageActions } from '@/views/Project/views/Header/types';

interface CloudFilesDialogProps {
  driveFilesType: 'none' | DriveFileType;
  onFileSelect: (file: DriveFile) => void;
  onClose: () => void;
}

const ROOT_FOLDER = 'None';
const CloudFilesDialog: FC<CloudFilesDialogProps> = ({ driveFilesType, onFileSelect, onClose }) => {
  const isOpened = driveFilesType !== 'none';
  const [folderId, setFolderId] = useState(ROOT_FOLDER);
  const [breadcrumbs, setBreadcrumbs] = useState<DriveFile[]>([]);

  const {
    data: googleDriveFiles,
    isLoading: isGoogleDriveFilesLoading,
    error: googleError,
  } = useListFiles<OneDriveFile[] | undefined>(
    { folder_id: folderId === ROOT_FOLDER ? undefined : folderId },
    { query: { enabled: driveFilesType === 'google', staleTime: 60_1000 } },
  );
  const {
    data: oneDriveFiles,
    isLoading: isOneDriveFilesLoading,
    error: microsoftError,
  } = useGetMicrosoftFiles<OneDriveFile[] | undefined>(
    { folder_id: folderId === ROOT_FOLDER ? undefined : folderId },
    { query: { enabled: driveFilesType === 'onedrive', staleTime: 60_1000 } },
  );

  const files = (driveFilesType === 'google' ? googleDriveFiles : oneDriveFiles) ?? [];

  useEffect(() => {
    if (!isOpened) {
      nextTick(() => {
        setFolderId(ROOT_FOLDER);
        setBreadcrumbs([]);
      });
    }
  }, [isOpened]);

  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set('action', HomepageActions.OPEN_EDIT_PROJECT_DIALOG);
  const url = new URL(window.location.href);
  url.search = urlParams.toString();
  const redirectUrl = url.toString();

  const onBreadcrumbRootClick = () => {
    setFolderId(ROOT_FOLDER);
    setBreadcrumbs([]);
  };

  const onBreadcrumbClick = (index: number) => {
    const nextFolder = breadcrumbs[index];
    setFolderId(nextFolder.id);
    setBreadcrumbs(breadcrumbs.slice(0, index));
  };

  const onFileClick = (item: OneDriveFile) => {
    if (item.folder) {
      setFolderId(item.id);
      setBreadcrumbs(prevBreadcrumbs => [...prevBreadcrumbs, item]);
    } else {
      onFileSelect(item);
    }
  };

  return (
    <Dialog open={isOpened} onClose={onClose}>
      {microsoftError && isSocialLoginError(microsoftError) && <Descope flowId="re-auth-microsoft" redirectUrl={redirectUrl} />}
      {googleError && isSocialLoginError(googleError) && <Descope flowId="re-auth-google" redirectUrl={redirectUrl} />}
      <Stack sx={{ gap: 1, height: 300 }}>
        <Box
          sx={{
            display: 'flex',
            gap: 0.5,
            alignItems: 'center',
            fontSize: 'body1.fontSize',
            lineHeight: 'body1.fontSize',
          }}
        >
          <ButtonBase
            sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}
            disabled={breadcrumbs.length === 0}
            onClick={() => onBreadcrumbRootClick()}
          >
            Root
          </ButtonBase>

          {breadcrumbs.map((breadcrumb, index) => (
            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
              <Box>/</Box>
              <ButtonBase
                sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}
                disabled={index === breadcrumbs.length - 1}
                onClick={() => onBreadcrumbClick(index)}
              >
                {breadcrumb.name}
              </ButtonBase>
            </Box>
          ))}
        </Box>

        {isGoogleDriveFilesLoading || isOneDriveFilesLoading ? (
          <CircularProgress sx={{ mt: 3 }} />
        ) : (
          <Stack gap={0.5} sx={{ pb: 3 }}>
            {Array.isArray(files) &&
              files.map(item => (
                <CloudFileView type={item.folder ? 'folder' : 'file'} name={item.name} onClick={() => onFileClick(item)} />
              ))}
          </Stack>
        )}
      </Stack>
    </Dialog>
  );
};

export default CloudFilesDialog;
