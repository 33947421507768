import { Instance, ViewState } from 'pspdfkit';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDocuments } from '@/views/Project/hooks/useDocuments';
import { useProject } from '@/views/Project/hooks/useProject';
import useRouteId from '@/hooks/useRouteId';
import { ROUTER_PATH_LINKER } from '@/services/linker';

type Params = {
  instance?: Instance;
  page: number;
  onPage: (page: number) => void;
};

export const usePageChangeEvent = ({ instance, page, onPage }: Params) => {
  const navigator = useNavigate();
  const { documentId } = useDocuments();
  const { projectSlug } = useProject();
  const routeId = useRouteId();

  useEffect(() => {
    if (!instance) return;
    const toProjectSidebarAware = ROUTER_PATH_LINKER[routeId];
    const isChangePage = page < instance.totalPageCount && page !== instance.viewState.currentPageIndex;
    isChangePage && instance.setViewState(viewState => viewState.set('currentPageIndex', page));

    const onPagesChange = (viewState: ViewState) => {
      onPage(viewState.currentPageIndex);
      navigator(
        toProjectSidebarAware({ projectSlug, documentId: documentId!, page: (viewState.currentPageIndex + 1).toString() }),
      );
    };
    instance.addEventListener('viewState.change', onPagesChange);
    return () => instance.removeEventListener('viewState.change', onPagesChange);
  }, [instance, page, onPage, routeId, projectSlug, documentId]);
};
