import { Box, InputAdornment, List, useTheme } from '@mui/material';
import Thread from '@/views/Knowledge/components/Thread';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import useKnowledge from '@/views/Knowledge/useKnowledge';
import { ThreadMetadata } from '@/api/generated';
import NoThreads from '@/views/Knowledge/components/NoThreads';
import FormInputSubmit, { FormInputSubmitRef } from '@/components/FormInputSubmit/FormInputSubmit';
import Icon from '@/components/Icon/Icon';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { TABS } from '@/views/Knowledge/components/consts';

interface ThreadsProps {
  setTabValue: (newVale: TABS) => void;
  tabValue: TABS;
}

export interface FormFilterQuery {
  filterThreads: string;
}

const validationSchemaFilterQuery = z.object({ filterThreads: z.string() }).required();

const Threads: FC<ThreadsProps> = ({ setTabValue, tabValue }) => {
  const { threads, setCurrentThreadId, isThreadLoading, isDeleteThreadPending, deleteThread, isActive } = useKnowledge();
  const { palette } = useTheme();
  const { t } = useTranslation('common');
  const formInputSubmitRef = useRef<FormInputSubmitRef>(null);
  const handleThread = useCallback(
    (thread_id: ThreadMetadata['thread_id']) => {
      setCurrentThreadId(thread_id);
      !isThreadLoading && setTabValue(TABS.CHAT);
    },
    [isThreadLoading, setCurrentThreadId, setTabValue],
  );

  const { register, watch } = useForm<FormFilterQuery>({
    resolver: zodResolver(validationSchemaFilterQuery),
    defaultValues: { filterThreads: '' },
  });

  useEffect(() => {
    if (isActive && formInputSubmitRef.current) {
      formInputSubmitRef.current.focus();
    }
  }, [tabValue, isActive]);

  const filterThreads = watch('filterThreads');

  const displayedThreads = useMemo(
    () =>
      threads
        ?.sort((a, b) => new Date(b?.last_modified ?? '').getTime() - new Date(a?.last_modified ?? '').getTime())
        ?.filter(({ title }) => title?.toLowerCase()?.includes(filterThreads.toLowerCase())),
    [filterThreads, threads],
  );

  const handleDeleteThread = async (thread_id: string) => await deleteThread(thread_id);

  return (
    <>
      {displayedThreads?.length ? (
        <List sx={{ p: 0, mb: 10 }}>
          {displayedThreads?.map(thread => (
            <Thread
              key={thread._id}
              thread={thread}
              handleThread={handleThread}
              onDelete={handleDeleteThread}
              isPendingDelete={isDeleteThreadPending}
            />
          ))}
        </List>
      ) : (
        <NoThreads setTabValue={setTabValue} />
      )}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '80%',
          left: '50%',
          transform: 'translateX(-50%)',
          mb: 3,
          zIndex: 1,
          backgroundColor: palette.background.default,
          borderRadius: '999px',
        }}
      >
        <FormInputSubmit
          register={register('filterThreads')}
          ref={formInputSubmitRef}
          placeholder={t('knowledge.placeholders.searchPlaceholder')}
          sx={{ '.MuiInputBase-input': { pl: 0, py: 1 }, pl: 3 }}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ color: palette.grey[700] }} position="start">
                <Icon name="search" fontSize="medium" />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default Threads;
